import classNames from "classnames";
import { Button } from "../../../components/Button/Button.tsx";
import { PimentoStar } from "../../../components/Icon/assets/PimentoStar.tsx";
import { useIsUserWorkspaceOwner } from "../../../hooks/useIsUserWorkspaceOwner.ts";
import { useUser } from "../../../hooks/useUser.ts";
import { useWorkspace } from "../../../hooks/useWorkspace.ts";
import { upgradeDialogStore } from "../../../singletons/UpgradeDialog/upgradeDialogStore.ts";
import { trackEvent } from "../../../utils/trackEvent.ts";
import { useCreateCustomerPortalUrl } from "../HomeIndex/hooks/useCreateCustomerPortalUrl.ts";

const WARNING_THRESHOLD = 20;

export const BalanceCallout = () => {
  const { user } = useUser();
  const { workspace } = useWorkspace();
  const { createCustomerPortalUrl, isCreateCustomerPortalUrlLoading } =
    useCreateCustomerPortalUrl();

  const isUserWorkspaceOwner = useIsUserWorkspaceOwner();

  if (!user) return null;
  return !["basic"].includes(workspace.type) ? (
    <div className="rounded-200 bg-primary p-400 flex-col gap-300">
      <div className="label-lg-semibold">Balance</div>
      <div className="flex-row items-center gap-150">
        <div
          className={classNames(
            "w-400 h-400 rounded-full flex-col-center",
            workspace.total_credits_remaining_amount < WARNING_THRESHOLD
              ? "bg-[var(--red-14)]"
              : "bg-surface-emphasis-primary-rest",
          )}
        >
          <PimentoStar size={8} className="fill-white" />
        </div>
        <div
          className={classNames(
            "label-lg-semibold",
            workspace.total_credits_remaining_amount < WARNING_THRESHOLD
              ? "text-[var(--red-14)]"
              : "",
          )}
        >
          {workspace.total_credits_remaining_amount}
          {workspace.total_credits_remaining_amount > 1
            ? " credits"
            : " credit"}{" "}
          left
        </div>
      </div>
      {isUserWorkspaceOwner && (
        <div className="pt-200">
          <Button
            size="sm"
            variant={
              workspace.total_credits_remaining_amount < WARNING_THRESHOLD
                ? "primary"
                : "secondary"
            }
            onClick={() => {
              createCustomerPortalUrl({ workspace_uuid: workspace.uuid });
            }}
            className="w-full"
            loading={isCreateCustomerPortalUrlLoading}
            disabled={isCreateCustomerPortalUrlLoading}
          >
            {workspace.total_credits_remaining_amount < WARNING_THRESHOLD
              ? "Upgrade"
              : "Manage subscription"}
          </Button>
        </div>
      )}
    </div>
  ) : (
    <div className="rounded-200 bg-surface-caution-primary-rest p-400 flex-col gap-300">
      <div className="label-lg-semibold">You’re missing out!</div>
      <div className="label-md-default">
        Custom models and watermark free downloads are waiting for you.
      </div>
      {isUserWorkspaceOwner ? (
        <div className="pt-200">
          <Button
            size="sm"
            onClick={() => {
              trackEvent("upgrade:display_popup", {
                reason: "upgrade_button_click",
              });
              upgradeDialogStore.openDialog({ isClosable: true });
            }}
          >
            Start 7-day trial
          </Button>
        </div>
      ) : (
        "Ask the workspace owner to start the 7-day trial"
      )}
    </div>
  );
};
