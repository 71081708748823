import classNames from "classnames";
import { Icon, type IconName } from "../../../../../components/Icon/Icon.tsx";
import type { ImageContent } from "../../../types.ts";

export const InformationHeader = ({
  image,
  variant = "light",
}: {
  image: ImageContent;
  variant?: "light" | "dark";
}) => {
  switch (image.generation_model_type) {
    case "text_to_image":
      return (
        <GenerationHeader
          generationType="Generate"
          iconName="Sparkles"
          variant={variant}
        />
      );
    case "image_to_image":
      return (
        <GenerationHeader
          generationType="Variations"
          iconName="Layers"
          variant={variant}
        />
      );
    case "style_transfer":
      return (
        <GenerationHeader
          generationType="Style transfer"
          iconName="ArrowLeftRight"
          variant={variant}
        />
      );
    case "upscaler":
      return (
        <GenerationHeader
          generationType="Upscale"
          iconName="Expand"
          variant={variant}
        />
      );
    case "generative_fill":
      return (
        <GenerationHeader
          generationType="Fill"
          iconName="Select"
          variant={variant}
        />
      );
    case "brief_to_image":
      return (
        <GenerationHeader
          generationType="Brief"
          iconName="Grid3x3"
          variant={variant}
        />
      );
    case null:
      return (
        <GenerationHeader generationType="Not generated" variant={variant} />
      );
  }
};

const GenerationHeader = ({
  iconName,
  generationType,
  variant = "light",
}: {
  iconName?: IconName;
  generationType: string;
  variant?: "light" | "dark";
}) => (
  <div className="flex-row items-center gap-100 overflow-hidden">
    {iconName && (
      <Icon
        name={iconName}
        className={
          variant === "dark" ? "stroke-inverse-rest" : "stroke-primary-rest"
        }
      />
    )}
    <div
      className={classNames(
        "label-md-default text-ellipsis",
        variant === "dark" ? "text-inverse-primary" : "text-primary",
      )}
    >
      {generationType}
    </div>
  </div>
);
