import classNames from "classnames";
import { useState } from "react";
import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { Button } from "../../../../components/Button/Button.tsx";
import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { useAppQuery } from "../../../../http/useAppQuery.ts";
import type { Style, StyleTag } from "../types.ts";

export const StyleTagMenu = ({ style }: { style: Style }) => {
  const { workspace } = useWorkspace();
  const { data: tags } = useAppQuery<StyleTag[]>({ queryKey: "style-tags/" });
  const [newTagName, setNewTagName] = useState("");

  const tagStyle = useAppMutation({
    path: "styles/add-tags",
    invalidate: [`workspaces/${workspace.uuid}/styles`],
  }).mutation;

  const untagStyle = useAppMutation({
    path: "styles/remove-tags",
    invalidate: [`workspaces/${workspace.uuid}/styles`],
  }).mutation;

  const createStyleTag = useAppMutation({
    path: "style-tags/create",
    invalidate: ["style-tags/"],
    onSuccess: (response: { data: { style_tag_uuid: string } }) =>
      tagStyle.mutate({
        style_uuid: style.uuid,
        tag_uuids: [response.data.style_tag_uuid],
      }),
  }).mutation;

  const createTag = () => {
    createStyleTag.mutate({ tag_name: newTagName });
    setNewTagName("");
  };

  return (
    <div className="flex-col w-full gap-200">
      <div className="text-primary body-lg-semibold">Style tags</div>
      <div className="flex-row flex-wrap gap-100">
        {tags?.map((tag) => (
          <BaseButton
            key={tag.uuid}
            className={classNames(
              "px-200 py-100 rounded-050 hover:opacity-70",
              style.tags.includes(tag.name)
                ? "text-white bg-surface-emphasis-primary-rest"
                : "bg-surface-primary-hover",
            )}
            onClick={() => {
              // FIXME: use tag uuid instead of names. It currently works because names are unique
              if (style.tags.includes(tag.name)) {
                untagStyle.mutate({
                  style_uuid: style.uuid,
                  tag_uuids: [tag.uuid],
                });
              } else {
                tagStyle.mutate({
                  style_uuid: style.uuid,
                  tag_uuids: [tag.uuid],
                });
              }
            }}
          >
            {tag.name}
          </BaseButton>
        ))}
      </div>
      <div className="flex-row items-center justify-end gap-400">
        <input
          autoFocus
          className="h-[30px] flex-fill px-100 resize-none rounded-100 border body-md-default text-primary placeholder:text-disabled"
          placeholder="Create new tag"
          value={newTagName}
          onKeyDown={(e) => {
            if (newTagName.isNotBlank() && e.key === "Enter") createTag();
          }}
          onChange={(event) => {
            setNewTagName(event.target.value);
          }}
        />
        <Button
          onClick={() => {
            if (newTagName.isNotBlank()) createTag();
          }}
          size="sm"
          className="!px-200"
          disabled={newTagName.isBlank()}
        >
          Create
        </Button>
      </div>
    </div>
  );
};
