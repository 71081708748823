import { useNavigate } from "react-router-dom";
import { Button } from "../../../components/Button/Button.tsx";
import { BaseDialog } from "../../../components/Dialog/BaseDialog.tsx";
import { PimentoStar } from "../../../components/Icon/assets/PimentoStar.tsx";
import { useUser } from "../../../hooks/useUser.ts";
import { useWorkspace } from "../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { WORKSPACES } from "../../../routes.ts";
import { upgradeDialogStore } from "../../../singletons/UpgradeDialog/upgradeDialogStore.ts";
import { trackEvent } from "../../../utils/trackEvent.ts";
import type { Style } from "../../types.ts";

// FIXME: this price should be determined by the backend
const TRAINING_COST = 20;

export const StyleTrainingPurchaseDialog = ({
  style,
  isOpen,
  onOpenChange,
  onTrainStyleSuccess,
}: {
  style: Style;
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
  onTrainStyleSuccess?: () => void;
}) => (
  <BaseDialog
    isOpen={isOpen}
    className="rounded-300 bg-surface-primary-rest"
    onOpenChange={(open) => onOpenChange(open)}
    content={
      isOpen && (
        <StyleTrainingPurchaseMenu
          style={style}
          onTrainStyleSuccess={onTrainStyleSuccess}
        />
      )
    }
  />
);

const StyleTrainingPurchaseMenu = ({
  style,
  onTrainStyleSuccess,
}: {
  style: Style;
  onTrainStyleSuccess?: () => void;
}) => {
  const { user } = useUser();
  const { workspace } = useWorkspace();
  const navigate = useNavigate();
  const { mutate: trainStyle, isLoading: isTrainStyleLoading } = useAppMutation(
    {
      path: "styles/train",
      invalidate: [
        `workspaces/${workspace.uuid}/styles`,
        `styles/${style.uuid}`,
      ],
      onSuccess: onTrainStyleSuccess,
    },
  ).mutation;
  return (
    <div className="w-[432px] py-100 flex-col">
      <div className="px-400 py-300 label-lg-semibold">
        Start training your model
      </div>
      <div className="px-400 py-100 ">
        <div className="py-400 bg-surface-inverse-rest rounded-200 flex-row-center">
          <PimentoStar
            size={70}
            className="fill-surface-caution-primary-rest"
          />
        </div>
      </div>
      <div className="px-400 py-300 flex-col gap-200 items-end">
        {user && (
          <div className="body-md-default text-secondary">
            {workspace.total_credits_remaining_amount < TRAINING_COST ? (
              <>
                You need {TRAINING_COST} credits to train a model. You have{" "}
                {workspace.total_credits_remaining_amount} credits left.
              </>
            ) : (
              <>{workspace.total_credits_remaining_amount} credits remaining.</>
            )}
          </div>
        )}
        <div className="flex-row items-center gap-200">
          <Button
            size="md"
            variant="tertiary"
            onClick={() => navigate(WORKSPACES)}
          >
            Later, save as draft
          </Button>
          {workspace.total_credits_remaining_amount < TRAINING_COST ? (
            <Button
              size="md"
              onClick={() => {
                trackEvent("upgrade:display_popup", {
                  reason: "style_training_purchase_button_click",
                });
                upgradeDialogStore.openDialog({ isClosable: true });
              }}
            >
              Upgrade plan
            </Button>
          ) : (
            <Button
              size="md"
              onClick={() => {
                switch (style.type) {
                  case null:
                    break;
                  case "style":
                    trainStyle({ uuid: style.uuid });
                    break;
                  case "object":
                  case "character":
                    trainStyle({
                      uuid: style.uuid,
                      workspace_uuid: workspace.uuid,
                      subject_description: style.subject_description ?? "",
                    });
                    break;
                }
              }}
              loading={isTrainStyleLoading}
            >
              Train your model for {TRAINING_COST} credits
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};
