import { useMemo } from "react";
import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { EditableText } from "../../../../components/EditableText/EditableText.tsx";
import { PimentoStar } from "../../../../components/Icon/assets/PimentoStar.tsx";
import { CDNImage } from "../../../../components/Image/CDNImage.tsx";
import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import type { Board } from "../types.ts";
import { BoardExtraMenu } from "./BoardExtraMenu.tsx";

export const BoardTile = ({
  board,
  onClick,
  isLoading,
  disabled,
}: {
  board: Board;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
}) => {
  const { workspace } = useWorkspace();
  const { mutate: nameMutate, isLoading: nameIsloading } = useAppMutation({
    path: "boards/update",
    invalidate: [`workspaces/${workspace.uuid}/boards`, `boards/${board.uuid}`],
  }).mutation;

  const lastEditionDateInfo = useMemo(() => {
    const now = new Date();
    const boardEditionDate = new Date(board.edited_at);
    const lastEditionDate = {
      days: Math.floor(
        (now.getTime() - boardEditionDate.getTime()) / (1000 * 60 * 60 * 24),
      ),
      hours: Math.floor(
        (now.getTime() - boardEditionDate.getTime()) / (1000 * 60 * 60),
      ),
      minutes: Math.floor(
        (now.getTime() - boardEditionDate.getTime()) / (1000 * 60),
      ),
    };
    return lastEditionDate.days > 0
      ? `Edited ${lastEditionDate.days} day${
          lastEditionDate.days === 1 ? "" : "s"
        } ago`
      : lastEditionDate.hours > 0
      ? `Edited ${lastEditionDate.hours} hour${
          lastEditionDate.hours === 1 ? "" : "s"
        } ago`
      : `Edited ${lastEditionDate.minutes} minute${
          lastEditionDate.minutes < 2 ? "" : "s"
        } ago`;
  }, [board.edited_at]);

  return (
    <div
      className="group relative flex-col border rounded-200 overflow-clip"
      style={{ width: "270px" }}
    >
      <BaseButton
        className="w-full"
        style={{ height: "180px" }}
        onClick={onClick}
        loading={isLoading}
        disabled={disabled}
      >
        {board.thumbnails_urls.length > 0 ? (
          <div className="grid grid-cols-3 grid-rows-2 bg-primary">
            {board.thumbnails_urls.map((thumbnail, key) => (
              <CDNImage
                key={key}
                src={thumbnail}
                className="h-[90px] w-[90px]"
                imageClassName="h-full w-full object-cover object-center"
                loading="lazy"
                srcDimension="medium256"
              />
            ))}
          </div>
        ) : (
          <div className="h-full w-full rounded-050 overflow-clip">
            <div className="flex-col-center h-full w-full bg-primary">
              <PimentoStar
                size={70}
                className="fill-surface-transparent-inverse-rest"
              />
            </div>
          </div>
        )}
      </BaseButton>
      <div className="flex-col p-400 gap-100">
        <EditableText
          text={board.name}
          onTextChange={(text) => nameMutate({ uuid: board.uuid, name: text })}
          textClassName="body-lg-semibold"
          inputClassName="text-primary rounded-100 body-lg-semibold"
          isLoading={nameIsloading}
          placeholder="Untitled project"
        />
        <div className="h-400 body-md-default text-secondary">
          {lastEditionDateInfo}
        </div>
      </div>
      <div className="absolute right-100 top-100">
        <BoardExtraMenu
          board={board}
          className="invisible group-hover:visible"
        />
      </div>
    </div>
  );
};
