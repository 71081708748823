import { useNavigate } from "react-router-dom";
import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { STYLES, WORKSPACES } from "../../../../routes.ts";

export const useCreateStyle = () => {
  const { workspace } = useWorkspace();
  const { mutate, isLoading } = useAppMutation({
    path: `workspaces/${workspace.uuid}/styles`,
    invalidate: [`workspaces/${workspace.uuid}/styles`],
    waitForInvalidation: false,
  }).mutation;

  const navigate = useNavigate();

  return {
    createStyle: () => {
      mutate(
        { name: "" },
        {
          onSuccess: ({ data }) => {
            navigate(
              `${WORKSPACES}/${workspace.uuid}/${STYLES}/${
                data.uuid as string
              }`,
            );
          },
        },
      );
    },
    isCreateStyleLoading: isLoading,
  };
};
