import { useNavigate } from "react-router-dom";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { Spinner } from "../../../components/Spinner/Spinner.tsx";
import { useImageDropZone } from "../../../hooks/useImageDropZone.ts";
import { useWorkspace } from "../../../hooks/useWorkspace.ts";
import {
  BOARDS,
  BRIEF_TOOL,
  EDIT_WORKSPACE,
  GENERATE_TOOL,
  TRANSFER_TOOL,
  WORKSPACES,
} from "../../../routes.ts";
import { addSearchParamsToPath } from "../../../utils/url.ts";
import { useAddImageOnBoard } from "../hooks/useAddImageOnBoard.ts";
import { useBoard } from "../hooks/useBoard.ts";
import {
  ToolMenuButton,
  ToolMenuNavigation,
} from "../Workspaces/components/ToolMenu/ToolMenuButton.tsx";
import { ToolMenuTooltipContent } from "../Workspaces/components/ToolMenu/ToolMenuTooltipContent.tsx";

export const CreateToolMenu = () => (
  <div className="flex-col">
    <ToolMenuNavigation
      to={GENERATE_TOOL}
      title="Generate"
      iconName="Sparkles"
      tooltipContent={
        <ToolMenuTooltipContent
          title="Generate"
          description="Prompt and generate"
          beforeImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/create_1_v2.png"
          afterImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/create_2.png"
          learnMoreLink="https://pimento.crisp.help/en/category/image-generation-5e5027/"
        />
      }
    />
    <ToolMenuNavigation
      to={TRANSFER_TOOL}
      title="Transfer"
      iconName="ArrowLeftRight"
      tooltipContent={
        <ToolMenuTooltipContent
          title="Transfer style"
          description="Apply your style to your image"
          beforeImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/transfer_1.png"
          afterImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/transfer_2.png"
          learnMoreLink="https://pimento.crisp.help/en/article/how-to-transfer-your-style-c7v46/"
        />
      }
    />
    <ToolMenuNavigation
      to={BRIEF_TOOL}
      title="Brief"
      iconName="Grid3x3"
      tooltipContent={
        <ToolMenuTooltipContent
          title="Brief"
          description="Generate images from a campaign brief"
          beforeImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/brief_1.png"
          afterImageUrl="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/navbar/tools/brief_2.png"
        />
      }
    />
    <UploadButton />
  </div>
);

const UploadButton = () => {
  const { workspace } = useWorkspace();
  const { board } = useBoard();
  const navigate = useNavigate();
  const { mutate: uploadImage, isLoading: isUploadLoading } =
    useAddImageOnBoard({
      onUploaded: (assetUuid: string) => {
        navigate(
          addSearchParamsToPath(
            `${WORKSPACES}/${workspace.uuid}/${BOARDS}/${board.uuid}//${EDIT_WORKSPACE}`,
            {
              assetUuid,
            },
          ),
        );
      },
      maxSize: 1024,
    });
  const { getInputProps, open: openFileBrowser } = useImageDropZone({
    multiple: false,
    minImageSize: 256,
    onImageDrop: (file) => {
      if (file) {
        uploadImage({
          image: file.fileToUpload,
        });
      }
    },
  });
  return (
    <ToolMenuButton
      tooltipContent={
        <div className="label-lg-semibold text-white">
          Upload an image to your project
        </div>
      }
    >
      <div className="w-full flex-col-center">
        <input {...getInputProps()} />
        <BaseButton
          className="w-full py-200 group flex-col-center gap-200"
          onClick={openFileBrowser}
        >
          <div className="flex-col-center p-200 rounded-200 h-[36px] group-hover:bg-input-surface-rest active-bg-surface-primary-hover">
            {isUploadLoading ? <Spinner size="sm" /> : <Icon name="Upload" />}
          </div>
          <div className="text-primary label-sm-semibold">Upload</div>
        </BaseButton>
      </div>
    </ToolMenuButton>
  );
};
