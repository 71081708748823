import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../components/Button/Button.tsx";
import { useUser } from "../../hooks/useUser.ts";
import { useAppMutation } from "../../http/useAppMutation.ts";
import { STYLES, WORKSPACES } from "../../routes.ts";
import { isUserAllowedTo } from "../../types.ts";

export const AdminView = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  const [targetUserEmail, setTargetUserEmail] = useState<string>();
  const [styleUuidToCopy, setStyleUuidToCopy] = useState<string>();
  const [newCopiedStyleUuid, setNewCopiedStyleUuid] = useState<string>();

  const copyStyle = useAppMutation({
    path: "styles/copy",
    invalidate: [],
    onSuccess: (response: { data: { uuid: string } }) =>
      setNewCopiedStyleUuid(response.data.uuid),
    onError: () => setNewCopiedStyleUuid(undefined),
  }).mutation;

  useEffect(() => {
    if (!(user && isUserAllowedTo(user, "styles:copy"))) navigate(WORKSPACES);
  }, [user, navigate]);
  return (
    <div className="flex-fill flex-col items-center text-primary">
      <div className="mt-800 heading-2xl">Admin</div>

      <div className="flex-col gap-200 w-[300px]">
        <div className="self-center mt-800 heading-xl">Copy styles</div>
        <div className="flex-col gap-100">
          <span className="body-lg-semibold">Style UUID</span>
          <input
            type="text"
            className="p-200 rounded-100 border-025 body-md-default text-primary placeholder:text-disabled"
            placeholder="aeaeae-ffff-eeee-ffff-a1a1a1a1"
            value={styleUuidToCopy}
            onChange={(event) => {
              setStyleUuidToCopy(event.target.value);
              setNewCopiedStyleUuid(undefined);
            }}
          />
        </div>
        <div className="flex-col gap-100">
          <span className="body-lg-semibold">Target user email</span>
          <input
            type="email"
            className="p-200 rounded-100 border-025 body-md-default text-primary placeholder:text-disabled"
            placeholder="user@email.com"
            value={targetUserEmail}
            onChange={(event) => {
              setTargetUserEmail(event.target.value);
              setNewCopiedStyleUuid(undefined);
            }}
          />
        </div>
        <Button
          disabled={!targetUserEmail || !styleUuidToCopy}
          size="md"
          loading={copyStyle.isLoading}
          onClick={() =>
            copyStyle.mutate({
              target_user_email: targetUserEmail,
              uuid: styleUuidToCopy,
            })
          }
        >
          Copy
        </Button>
        {newCopiedStyleUuid !== undefined && (
          <Link
            to={`${STYLES}/${newCopiedStyleUuid}`}
            className="self-center text-primary underline hover:text-secondary"
          >
            Go to new copied style
          </Link>
        )}
      </div>
    </div>
  );
};
