// Root
export const ADMIN = "/admin";
export const WORKSPACES = "/workspaces";
export const PAYMENT_CONFIRMATION = "/payment/confirmation";

// Workspace
export const HOME = "home";
export const BOARDS = "boards";
export const STYLES = "styles";

// Workspace - Home
export const PURCHASES = "purchases";

// Workspace - Board - Workspaces
export const CREATE_WORKSPACE = "create";
export const EDIT_WORKSPACE = "edit";

// Workspace - Board - Tools
export const GENERATE_TOOL = "generate";
export const GENERATE_TOOL_PATH =
  `${CREATE_WORKSPACE}/${GENERATE_TOOL}` as const;

export const TRANSFER_TOOL = "transfer";
export const TRANSFER_TOOL_PATH =
  `${CREATE_WORKSPACE}/${TRANSFER_TOOL}` as const;

export const BRIEF_TOOL = "brief";
export const BRIEF_TOOL_PATH = `${CREATE_WORKSPACE}/${BRIEF_TOOL}` as const;

export const VARIATION_TOOL = "variation";
export const VARIATION_TOOL_PATH =
  `${EDIT_WORKSPACE}/${VARIATION_TOOL}` as const;

export const FILL_TOOL = "fill";
export const FILL_TOOL_PATH = `${EDIT_WORKSPACE}/${FILL_TOOL}` as const;

export const UPSCALE_TOOL = "upscale";
export const UPSCALE_TOOL_PATH = `${EDIT_WORKSPACE}/${UPSCALE_TOOL}` as const;
