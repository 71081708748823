import { Icon } from "../../../../../components/Icon/Icon.tsx";
import { Image } from "../../../../../components/Image/Image.tsx";
import { LearnMoreLink } from "../../../components/LearnMoreLink.tsx";

export const ToolMenuTooltipContent = ({
  title,
  description,
  beforeImageUrl,
  afterImageUrl,
  learnMoreLink,
}: {
  title: string;
  description: string;
  beforeImageUrl: string;
  afterImageUrl: string;
  learnMoreLink?: string;
}) => (
  <div className="flex-col gap-200">
    <div className="flex-row-center gap-100">
      <Image
        className="h-[120px] aspect-square"
        imageClassName="h-full w-full object-contain object-center"
        src={beforeImageUrl}
      />
      <Icon name="ArrowRight" size="lg" className="stroke-inverse-rest" />
      <Image
        className="h-[120px] aspect-square"
        imageClassName="h-full w-full object-contain object-center"
        src={afterImageUrl}
      />
    </div>
    <div className="flex-col gap-100">
      <div className="flex-row items-center justify-between">
        <span className="label-lg-semibold text-white">{title}</span>
        {learnMoreLink && (
          <LearnMoreLink url={learnMoreLink} variant="secondary" />
        )}
      </div>
      <span className="text-inverse-primary">{description}</span>
    </div>
  </div>
);
