import { useQueryClient } from "@tanstack/react-query";
import { useWorkspace } from "../../../../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../../../../http/useAppMutation.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import { useSelectedStylesGenerationParams } from "../../../../../hooks/useSelectedStylesGenerationParams.ts";
import { useUpscaleSettings } from "./useUpscaleSettings.ts";

export const useUpscaleImageGeneration = ({
  onSuccess,
}: {
  onSuccess?: () => void;
}) => {
  const { workspace } = useWorkspace();
  const { board } = useBoard();
  const generateUpscaleImage = useAppMutation({
    path: "boards/upscale-image",
    invalidate: [`workspaces/${workspace.uuid}/boards`, `boards/${board.uuid}`],
    onSuccess,
  }).mutation;

  const { upscaleSettings } = useUpscaleSettings();

  const queryClient = useQueryClient();

  const { selectedStylesGenerationParams } =
    useSelectedStylesGenerationParams();

  return {
    mutation: ({
      prompt,
      assetUuid,
    }: {
      prompt: string;
      assetUuid: string;
    }) => {
      if (!selectedStylesGenerationParams.length) return;

      generateUpscaleImage.mutate(
        {
          board_uuid: board.uuid,
          prompt,
          style_uuid: selectedStylesGenerationParams[0].uuid,
          asset_uuid: assetUuid,
          imagination_strength_preset:
            upscaleSettings.imagination_strength_preset,
          resemblance:
            upscaleSettings.imagination_strength_preset === "custom"
              ? upscaleSettings.resemblance
              : undefined,
          creativity:
            upscaleSettings.imagination_strength_preset === "custom"
              ? upscaleSettings.creativity
              : undefined,
          scale_factor: upscaleSettings.scale_factor,
          negative_prompt: upscaleSettings.negative_prompt,
          lora_scale: selectedStylesGenerationParams[0].scale,
        },
        {
          onSuccess: () => {
            [`assets/${assetUuid}`, `assets/${assetUuid}/history`].map(
              (queryKey) =>
                void queryClient.invalidateQueries({ queryKey: [queryKey] }),
            );
          },
        },
      );
    },
    isLoading: generateUpscaleImage.isLoading,
  };
};
