import { useUser } from "../../../hooks/useUser.ts";
import { useWorkspace } from "../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import { isUserAllowedTo } from "../../../types.ts";
import { GENERIC_STYLE_UUID } from "../../constants.ts";
import type { Style } from "../../types.ts";

type StyleRetrainStatus =
  | "upToDate"
  | "training"
  | "retrainable"
  | "nonRetrainable";

export const useRetrainStyle = ({ styleUuid }: { styleUuid: string }) => {
  const { user } = useUser();
  const { workspace } = useWorkspace();
  const { data: style } = useAppQuery<Style>({
    queryKey: `styles/${styleUuid}`,
  });
  const { mutate: retrainMutate, isLoading } = useAppMutation({
    path: "styles/retrain",
    invalidate: [`workspaces/${workspace.uuid}/styles`, `styles/${styleUuid}`],
  }).mutation;

  const isUserAllowed = style?.is_curated
    ? user && isUserAllowedTo(user, "styles:update-curated-styles")
    : !style?.is_curated;

  //  FIXME: We should be able to distinguish generic style here so it's not considered as retrainable
  const styleRetrainStatus: StyleRetrainStatus = style?.is_mixable
    ? "upToDate"
    : style?.status === "training"
    ? "training"
    : style?.uuid === GENERIC_STYLE_UUID
    ? "nonRetrainable"
    : style
    ? "retrainable"
    : "nonRetrainable";

  const isDisabled = styleRetrainStatus !== "retrainable";

  return {
    retrain: () => retrainMutate({ uuid: styleUuid }),
    isLoading,
    isDisabled,
    isUserAllowed,
    styleRetrainStatus,
  };
};
