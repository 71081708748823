import classNames from "classnames";
import { NavLink } from "react-router-dom";
import { Pimento } from "../../../components/Icon/assets/Pimento.tsx";
import { Icon, type IconName } from "../../../components/Icon/Icon.tsx";
import { useAppQuery } from "../../../http/useAppQuery.ts";
import { PURCHASES, WORKSPACES } from "../../../routes.ts";
import type { UserPurchasedContents } from "../Purchased/types.ts";
import { BalanceCallout } from "./BalanceCallout.tsx";
import { UserMenu } from "./UserMenu.tsx";

export const HomeSidebar = () => {
  const { data: userPurchasedContents } = useAppQuery<UserPurchasedContents>({
    queryKey: "users/get-purchased-contents",
  });

  return (
    <div className="flex-col w-[240px] justify-between pt-150">
      <div className="flex-col px-300 py-150 gap-300">
        <UserMenu />
        <div>
          <HomeNavigationButton to={WORKSPACES} iconName="Home" title="Home" />
          <HomeNavigationButton
            to={PURCHASES}
            iconName="ShoppingBasket"
            title="Purchases"
            rightText={(userPurchasedContents?.contents.length ?? 0).toString()}
          />
        </div>
      </div>
      <div className="flex-col p-300 gap-200">
        <BalanceCallout />
        <div className="px-200 py-300">
          <Pimento size={90} />
        </div>
      </div>
    </div>
  );
};

const HomeNavigationButton = ({
  to,
  title,
  iconName,
  rightText,
}: {
  to: string;
  iconName: IconName;
  title: string;
  rightText?: string;
}) => (
  <NavLink
    to={to}
    className={({ isActive }) =>
      classNames(
        "h-800 pl-150 pr-200 flex-row items-center justify-between rounded-150",
        isActive
          ? "bg-surface-primary-active label-md-semibold text-primary"
          : "label-md-default text-secondary stroke-secondary-rest hover:bg-surface-primary-hover",
      )
    }
    end
  >
    {({ isActive }) => (
      <>
        <div className="flex-row-center gap-150 ">
          <Icon
            name={iconName}
            className={
              isActive ? "stroke-secondary-active" : "stroke-secondary-rest"
            }
          />
          <div>{title}</div>
        </div>
        {rightText && <div>{rightText}</div>}
      </>
    )}
  </NavLink>
);
