import { useEffect } from "react";
import { z } from "zod";
import { createStorageStore } from "../../../../../../../utils/createStore.ts";
import {
  ML_MODEL_ARCHITECTURES,
  QUALITY_PRESETS,
} from "../../../../../constants.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";

const zBriefSettings = z.object({
  quality_preset: z.enum(QUALITY_PRESETS),
  ml_model_architecture: z.enum(ML_MODEL_ARCHITECTURES).optional(),
  enable_flux_prompt_enhancement: z.boolean(),
});

export type BriefSettings = z.infer<typeof zBriefSettings>;

const BRIEF_DEFAULT_SETTINGS = {
  quality_preset: "fast",
  ml_model_architecture: undefined,
  enable_flux_prompt_enhancement: true,
} as const;

export const useBriefSettingsAtom = createStorageStore(
  localStorage,
  "board-brief-settings",
  z.record(zBriefSettings.optional()),
  {},
  ({ update }) => ({
    updateBriefSettings: (
      boardUuid: string,
      newSettings: Partial<BriefSettings>,
    ) => {
      update((draft) => {
        const existingSettings = draft[boardUuid]!;
        draft[boardUuid] = { ...existingSettings, ...newSettings };
      });
    },
  }),
);

export const useBriefSettings = () => {
  const { board } = useBoard();
  const briefSettings = useBriefSettingsAtom.useState((s) => s[board.uuid]);

  useEffect(() => {
    if (briefSettings === undefined) {
      useBriefSettingsAtom.updateBriefSettings(
        board.uuid,
        BRIEF_DEFAULT_SETTINGS,
      );
    }
  }, [briefSettings, board.uuid]);

  return briefSettings ?? BRIEF_DEFAULT_SETTINGS;
};
