import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { DeletionDialog } from "../components/DeletionDialog.tsx";
import type { Board } from "../types.ts";

export const BoardDeletionDialog = ({
  board,
  dialogOpen,
  onDialogChange,
  onCancel,
}: {
  board: Board;
  dialogOpen: boolean;
  onDialogChange: (dialogChange: boolean) => void;
  onCancel: () => void;
}) => {
  const { workspace } = useWorkspace();
  const deleteBoardMutation = useAppMutation({
    path: "boards/delete",
    invalidate: [`workspaces/${workspace.uuid}/boards`],
  }).mutation;

  return (
    <DeletionDialog
      onDialogChange={onDialogChange}
      dialogOpen={dialogOpen}
      onClick={() => {
        deleteBoardMutation.mutate({
          uuid: board.uuid,
        });
      }}
      isLoading={deleteBoardMutation.isLoading}
      onCancel={onCancel}
    >
      <div className="flex-col-center gap-200 text-primary">
        <div className="flex-col-center gap-050">
          <span className="heading-xl">Delete board</span>
          <span className="label-lg-semibolds">
            {board.name !== "" ? board.name : "Untitled"}
          </span>
        </div>
        {/* FIXME: Improve tailwind classes by using gap instead of explicit margin */}
        <div className="mt-200 mb-1200 body-md-default">
          This board will not be accessible anymore.
        </div>
      </div>
    </DeletionDialog>
  );
};
