import { useState } from "react";
import { useOnMount } from "../../hooks/useOnMount.ts";
import { useAppMutation } from "../../http/useAppMutation.ts";
import type { CharacterStyle, ObjectStyle } from "../types.ts";
import { FORM_INPUT_WIDTH_CSS } from "./constants.ts";
import { ObjectAndCharacterDescriptionForm } from "./CreationPage/ObjectAndCharacterDescriptionForm.tsx";
import { StyleInitSplashScreen } from "./CreationPage/StyleInitSplashScreen.tsx";
import { StyleNameForm } from "./CreationPage/StyleNameForm.tsx";
import { TrainingImagesForm } from "./CreationPage/TrainingImagesForm.tsx";
import { StyleDescription } from "./StyleDescription.tsx";
import { StyleTrainingPurchaseDialog } from "./StyleTrainingPurchaseDialog/StyleTrainingPurchaseDialog.tsx";
import type { ObjectAndCharacterCreationStep } from "./types.ts";

export const ObjectAndCharacterModelCreation = ({
  style,
  styleType,
}: {
  style: ObjectStyle | CharacterStyle;
  styleType: "object" | "character";
}) => {
  const [currentModelCreationStep, setCurrentModelCreationStep] =
    useState<ObjectAndCharacterCreationStep>("modelTrainingImagesForm");
  const [
    isTrainingStylePurchaseDialogOpen,
    setIsTrainingStylePurchaseDialogOpen,
  ] = useState(false);

  const generateSubjectDescription = useAppMutation({
    path: "styles/generate-subject-description",
    invalidate: [],
  }).mutation;

  useOnMount(() => {
    //  XXX: If we start again the onboarding we set the step to the latest step that was reached
    if (style.status === "ready") {
      setCurrentModelCreationStep("modelDescription");
    } else if (style.status === "training") {
      setCurrentModelCreationStep("modelInitSplashScreen");
    } else if (style.status === "created") {
      if (
        style.subject_description?.length === 0 ||
        style.subject_description === null
      ) {
        setCurrentModelCreationStep("modelTrainingImagesForm");
      } else if (style.name === null) {
        setCurrentModelCreationStep("modelNameForm");
      } else {
        setCurrentModelCreationStep("modelNameForm");
      }
    }
  });

  // FIXME: align step names and component names
  switch (currentModelCreationStep) {
    case "modelTrainingImagesForm":
      return (
        <TrainingImagesForm
          style={style}
          widthClassName={FORM_INPUT_WIDTH_CSS}
          onClickNext={() => {
            setCurrentModelCreationStep("modelSubjectDescriptionForm");
            generateSubjectDescription.mutate({ style_uuid: style.uuid });
          }}
        />
      );
    case "modelSubjectDescriptionForm":
      return (
        <ObjectAndCharacterDescriptionForm
          style={style}
          styleType={styleType}
          widthClassName={FORM_INPUT_WIDTH_CSS}
          onClickNext={() => {
            setCurrentModelCreationStep("modelNameForm");
          }}
          onClickPrevious={() => {
            setCurrentModelCreationStep("modelTrainingImagesForm");
          }}
        />
      );
    case "modelNameForm":
      return (
        <>
          <StyleNameForm
            widthClassName={FORM_INPUT_WIDTH_CSS}
            style={style}
            onClickPrevious={() =>
              setCurrentModelCreationStep("modelSubjectDescriptionForm")
            }
            onClickNext={() => {
              setIsTrainingStylePurchaseDialogOpen(true);
            }}
          />
          <StyleTrainingPurchaseDialog
            isOpen={isTrainingStylePurchaseDialogOpen}
            onOpenChange={setIsTrainingStylePurchaseDialogOpen}
            style={style}
            onTrainStyleSuccess={() =>
              setCurrentModelCreationStep("modelInitSplashScreen")
            }
          />
        </>
      );
    case "modelInitSplashScreen":
      return <StyleInitSplashScreen widthClassName={FORM_INPUT_WIDTH_CSS} />;
    case "modelDescription":
      return <StyleDescription style={style} />;
  }
};
