import { useEffect } from "react";
import { z } from "zod";
import { Form } from "../../../../../../components/Form/Form.tsx";
import { useForm } from "../../../../../../components/Form/FormContext.ts";
import { useUser } from "../../../../../../hooks/useUser.ts";
import { useAppQuery } from "../../../../../../http/useAppQuery.ts";
import { isUserAllowedTo } from "../../../../../../types.ts";
import { addNullInput } from "../../../../../../utils/validations.ts";
import type { Style } from "../../../../../types.ts";
import { useBoard } from "../../../../hooks/useBoard.ts";
import { useSelectedStylesGenerationParams } from "../../../../hooks/useSelectedStylesGenerationParams.ts";
import { GenerationBar } from "../../../components/GenerationBar/GenerationBar.tsx";
import { CollapsableSettingsSectionWrapper } from "../../../components/SettingsMenu/CollapsableSettingsSectionWrapper.tsx";
import { MlModelArchitectureSection } from "../../../components/SettingsMenu/MlModelArchitectureSection.tsx";
import { QualityPresetSection } from "../../../components/SettingsMenu/QualityPresetSection.tsx";
import { SwitchSection } from "../../../components/SettingsMenu/SwitchSection.tsx";
import { SettingsMenuLayout } from "../../../components/SettingsMenuLayout.tsx";
import { scrollCreateWorkspaceGridToTop } from "../../utils/scrollCreateWorkspaceGridToTop.ts";
import {
  useBriefSettings,
  useBriefSettingsAtom,
} from "./hooks/useBriefSettings.ts";
import { useImageFromBriefGeneration } from "./hooks/useImageFromBriefGeneration.ts";
import { briefPromptStore } from "./stores/briefPromptStore.ts";

export const BriefSettings = () => {
  const { mutation: generateFromBrief, isLoading } =
    useImageFromBriefGeneration({
      onSuccess: scrollCreateWorkspaceGridToTop,
    });

  return (
    <Form
      className="flex-col flex-fill"
      schema={zBriefSettingsValidationSchema}
      initialValues={{
        prompt: "",
        selectedStyleUuid: null,
        areMultipleStylesSelected: false,
      }}
      onSubmit={(values) => {
        // FIXME: make the mutation async
        generateFromBrief({
          prompt: values.prompt,
        });
        return Promise.resolve(true);
      }}
    >
      <BriefSettingsFormContent isGenerationLoading={isLoading} />
    </Form>
  );
};

const zBriefSettingsValidationSchema = z.object({
  selectedStyleUuid: addNullInput(z.string().uuid(), "Please select a model"),
  areMultipleStylesSelected: z.boolean().refine((val) => !val, {
    message: "Multiple model not available with this tool",
  }),
  prompt: z.string().min(1, { message: "Please enter a brief" }),
});
type BriefSettingsValidationSchemaValues = z.input<
  typeof zBriefSettingsValidationSchema
>;

const BriefSettingsFormContent = ({
  isGenerationLoading,
}: {
  isGenerationLoading: boolean;
}) => {
  const { board } = useBoard();
  const briefSettings = useBriefSettings();
  const { selectedStylesGenerationParams } =
    useSelectedStylesGenerationParams();
  const { prompt } = briefPromptStore.useState();
  const { user } = useUser();

  const { data: style } = useAppQuery<Style>({
    queryKey: selectedStylesGenerationParams.length
      ? `styles/${selectedStylesGenerationParams[0].uuid}`
      : null,
    enabled: !!selectedStylesGenerationParams.length,
  });

  const { setValues, submit, useError } =
    useForm<BriefSettingsValidationSchemaValues>();
  const missingStyleError = useError((v) => v.selectedStyleUuid);
  const areMultipleStylesSelectedError = useError(
    (v) => v.areMultipleStylesSelected,
  );

  useEffect(() => {
    setValues({ prompt });
  }, [setValues, prompt]);

  useEffect(() => {
    setValues({
      selectedStyleUuid: selectedStylesGenerationParams.length
        ? selectedStylesGenerationParams[0].uuid
        : null,
      areMultipleStylesSelected: selectedStylesGenerationParams.length > 1,
    });
  }, [setValues, selectedStylesGenerationParams]);

  return (
    <SettingsMenuLayout
      body={
        <div className="flex-col">
          {user && isUserAllowedTo(user, "mode:debug") && (
            <div className="border-t">
              <CollapsableSettingsSectionWrapper
                name="Debug mode"
                content={
                  <div className="flex-col gap-200">
                    {(style?.type === "style" || style?.type === "object") &&
                      style.ml_model_architectures.includes("flux") && (
                        <SwitchSection
                          switchName="Style Enhancement"
                          switchInformationSection="Activate it to better catch your style details such as colors ... This is still experimental and can lead to some prompt inconsistencies."
                          value={briefSettings.enable_flux_prompt_enhancement}
                          onChange={(enable) => {
                            useBriefSettingsAtom.updateBriefSettings(
                              board.uuid,
                              {
                                enable_flux_prompt_enhancement: enable,
                              },
                            );
                          }}
                          isBeta
                        />
                      )}
                    <MlModelArchitectureSection
                      style={style}
                      mlModelArchitecture={briefSettings.ml_model_architecture}
                      onClickMlModelArchitecture={(mlModelArchitecture) =>
                        useBriefSettingsAtom.updateBriefSettings(board.uuid, {
                          ml_model_architecture: mlModelArchitecture,
                        })
                      }
                    />
                    <QualityPresetSection
                      value={briefSettings.quality_preset}
                      onChange={(preset) =>
                        useBriefSettingsAtom.updateBriefSettings(board.uuid, {
                          quality_preset: preset,
                        })
                      }
                    />
                  </div>
                }
              />
            </div>
          )}
        </div>
      }
      footer={
        <GenerationBar
          onGenerate={submit}
          isLoading={isGenerationLoading}
          prompt={prompt}
          setPrompt={(newPrompt) => {
            briefPromptStore.setPrompt(newPrompt);
          }}
          promptError={useError((v) => v.prompt)}
          sectionTitle="Campaign brief"
          buttonContent="Generate campaign images"
          placeholder="Describe your campaign goals and periodicity, feel free to add any relevant context"
          autoFocus
          extraErrors={[
            missingStyleError,
            areMultipleStylesSelectedError,
          ].filter((error): error is string => error !== undefined)}
        />
      }
    />
  );
};
