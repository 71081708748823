import { useParams } from "react-router";
import type { WorkspaceDetails } from "../types.ts";
import { useCachedQuery } from "./useCachedQuery.ts";

export const useWorkspace = () => {
  const workspaceUuid = useParams()["workspaceUuid"]!;
  return {
    workspace: useCachedQuery<WorkspaceDetails>({
      queryKey: `workspaces/${workspaceUuid}`,
    }),
  };
};
