import { Route } from "react-router";
import { Link, Routes } from "react-router-dom";
import { Icon } from "../../../components/Icon/Icon.tsx";
import {
  BRIEF_TOOL_PATH,
  FILL_TOOL_PATH,
  GENERATE_TOOL_PATH,
  TRANSFER_TOOL_PATH,
  UPSCALE_TOOL_PATH,
  VARIATION_TOOL_PATH,
} from "../../../routes.ts";
import { applyCurrentSearchParamsToPath } from "../../../utils/url.ts";
import { getWorkspace } from "../utils/getWorkspace.ts";
import { StylesSelectionSection } from "../Workspaces/components/SettingsMenu/StylesSelectionSection.tsx";
import { BriefSettings } from "../Workspaces/CreateWorkspace/Tools/BriefView/BriefSettings.tsx";
import { GenerateSettings } from "../Workspaces/CreateWorkspace/Tools/GenerateView/Settings/GenerateSettings.tsx";
import { StyleTransferSettings } from "../Workspaces/CreateWorkspace/Tools/StyleTransferView/StyleTransferSettings.tsx";
import { GenerativeFillSettings } from "../Workspaces/EditWorkspace/Tools/GenerativeFillView/GenerativeFillSettings.tsx";
import { UpscaleSettings } from "../Workspaces/EditWorkspace/Tools/UpscaleView/UpscaleSettings.tsx";
import { VariationSettings } from "../Workspaces/EditWorkspace/Tools/VariationView/VariationSettings.tsx";

export const SettingsMenu = () => {
  const workspace = getWorkspace();
  return (
    <div className="flex-col h-full w-[270px] border-r bg-surface-primary-rest text-primary">
      <div className="h-[54px] p-400 flex-row items-center justify-between border-b-025">
        <div className="body-lg-semibold">
          <Routes>
            <Route path={GENERATE_TOOL_PATH} element="Generate images" />
            <Route path={TRANSFER_TOOL_PATH} element="Transfer style" />
            <Route path={BRIEF_TOOL_PATH} element="Campaign brief generation" />
            <Route path={FILL_TOOL_PATH} element="Fill" />
            <Route path={VARIATION_TOOL_PATH} element="Generate variations" />
            <Route path={UPSCALE_TOOL_PATH} element="Upscale" />
          </Routes>
        </div>
        <Link to={workspace ? applyCurrentSearchParamsToPath(workspace) : ""}>
          <Icon name="X" />
        </Link>
      </div>
      <StylesSelectionSection />
      <Routes>
        <Route path={GENERATE_TOOL_PATH} element={<GenerateSettings />} />
        <Route path={TRANSFER_TOOL_PATH} element={<StyleTransferSettings />} />
        <Route path={BRIEF_TOOL_PATH} element={<BriefSettings />} />
        <Route path={FILL_TOOL_PATH} element={<GenerativeFillSettings />} />
        <Route path={VARIATION_TOOL_PATH} element={<VariationSettings />} />
        <Route path={UPSCALE_TOOL_PATH} element={<UpscaleSettings />} />
      </Routes>
    </div>
  );
};
