import { useAuth0 } from "@auth0/auth0-react";
import classNames from "classnames";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Button } from "../../../components/Button/Button.tsx";
import { PimentoStar } from "../../../components/Icon/assets/PimentoStar.tsx";
import { Icon, type IconName } from "../../../components/Icon/Icon.tsx";
import { Popover } from "../../../components/Popover/Popover.tsx";
import { useUser } from "../../../hooks/useUser.ts";
import { useWorkspace } from "../../../hooks/useWorkspace.ts";
import { ADMIN, WORKSPACES } from "../../../routes.ts";
import { isUserAllowedTo } from "../../../types.ts";
import { ProfileSettingsDialog } from "./ProfileSettingsDialog.tsx";

export const UserMenu = () => {
  const { user } = useUser();
  const { workspace } = useWorkspace();
  const navigate = useNavigate();

  const { logout } = useAuth0();
  const [isUserMenuOpen, setIsUserMenuOpen] = useState(false);
  const [isProfileSettingsDialogOpen, setIsProfileSettingsDialogOpen] =
    useState(false);

  // FIXME: keep the popover open when Profile settings dialog is open
  return (
    <>
      <Popover
        isOpen={isUserMenuOpen}
        onOpenChange={setIsUserMenuOpen}
        align="start"
        side="top"
        sideOffset={4}
        className="w-[256px] rounded-300"
        content={
          <div className="flex-col divide-y-025 divide-solid">
            <div className="flex-col gap-300 p-300">
              <div className="flex-row items-center gap-150">
                <div className="flex-col-center rounded-150 p-50 w-500 h-500  bg-[#1A1A1A]">
                  <PimentoStar className="fill-[#F3F20E]" size={12} />
                </div>
                <div className="flex-col">
                  <div className="label-md-semibold text-primary">
                    {user?.first_name && user.last_name
                      ? `${user.first_name} ${user.last_name}`
                      : user?.email}
                  </div>
                  <div className="label-sm-semibold text-secondary">
                    {workspace.type.upperFirst()} plan{" "}
                    {workspace.num_members
                      ? workspace.num_members === 1
                        ? " • 1 member"
                        : ` • ${workspace.num_members} members`
                      : ""}
                  </div>
                </div>
              </div>
              <Button
                onClick={() => setIsProfileSettingsDialogOpen(true)}
                variant="secondary"
                iconName="Settings"
                size="md"
              >
                <div className="label-md-default">Settings</div>
              </Button>
              <div className="text-disabled label-md-semibold">
                {user?.email}
              </div>
              <div className="flex-col w-full">
                {user?.workspaces.map((item) => (
                  <BaseButton
                    key={item.uuid}
                    className={classNames(
                      "h-800 pl-150 pr-200 rounded-150 flex-fill text-primary ",
                      workspace.uuid === item.uuid
                        ? "label-md-semibold bg-surface-secondary-active"
                        : "label-md-default bg-button-surface-secondary-rest:bg-button-surface-secondary-hover hover:bg-button-surface-secondary-hover",
                    )}
                    onClick={() => {
                      navigate(`${WORKSPACES}/${item.uuid}/`);
                    }}
                  >
                    <div className="flex-row items-center gap-150">
                      <div className="flex-col-center rounded-150 p-50 w-500 h-500  bg-[#1A1A1A]">
                        <PimentoStar className="fill-[#F3F20E]" size={12} />
                      </div>
                      <div className="flex-fill">{item.name}</div>
                      {workspace.uuid === item.uuid && (
                        <Icon name="Check" size="sm" />
                      )}
                    </div>
                  </BaseButton>
                ))}
              </div>
            </div>
            <div className="flex-col p-300">
              {user && isUserAllowedTo(user, "styles:copy") && (
                <Link to={ADMIN}>
                  <UserMenuItem iconName="Lock" title="Admin" />
                </Link>
              )}
              <a href="https://pimento.crisp.help/en/" target="_blank">
                <UserMenuItem
                  iconName="LifeBuoy"
                  title="Help center"
                  externalLink
                />
              </a>
              <a href="https://gopimento.notion.site/" target="_blank">
                <UserMenuItem
                  iconName="ScrollText"
                  title="Terms and conditions"
                  externalLink
                />
              </a>
              <ClickableUserMenuItem
                iconName="LogOut"
                title="Log out"
                onClick={() => logout({ returnTo: window.location.origin })}
              />
            </div>
          </div>
        }
      >
        <BaseButton
          className="pl-150 pr-200 py-300 w-full flex-row items-center justify-between rounded-150 text-secondary
        label-md-default hover:bg-surface-primary-hover focus:text-primary focus:label-md-semibold
        focus:bg-surface-primary-active"
        >
          <div className="flex-row items-center h-[22px] gap-150 overflow-visible">
            <div className="flex-col-center rounded-150 p-50 w-500 h-500  bg-[#1A1A1A]">
              <PimentoStar className="fill-[#F3F20E]" size={12} />
            </div>
            <div className="flex-col">
              <div className="label-md-semibold text-primary">
                {user?.first_name && user.last_name
                  ? `${user.first_name[0]}. ${user.last_name}`
                  : user?.email}
              </div>
              <div className="label-sm-semibold text-secondary">
                {workspace.name || "No workspace selected"}
              </div>
            </div>
          </div>
          <Icon
            name="ChevronsUpDown"
            size="sm"
            className={classNames(
              "transition-transform",
              isUserMenuOpen && "-rotate-180",
            )}
          />
        </BaseButton>
      </Popover>
      <ProfileSettingsDialog
        isOpen={isProfileSettingsDialogOpen}
        onOpenChange={(open) => setIsProfileSettingsDialogOpen(open)}
      />
    </>
  );
};
const UserMenuItem = ({
  title,
  iconName,
  externalLink = false,
}: {
  title: string;
  iconName: IconName;
  externalLink?: boolean;
}) => (
  <div
    className={classNames(
      "px-200 py-100 h-800 rounded-150 flex-row items-center gap-200 bg-surface-primary-rest hover:bg-surface-primary-hover",
    )}
  >
    <div className="flex-fill flex-row items-center gap-200">
      <div className="flex-row-center w-400">
        <Icon name={iconName} size="sm" />
      </div>
      <div className="label-md-default">{title}</div>
    </div>
    {externalLink && <Icon name="ExternalLink" size="sm" />}
  </div>
);

const ClickableUserMenuItem = ({
  title,
  iconName,
  onClick,
}: {
  title: string;
  iconName: IconName;
  onClick: () => void;
}) => (
  <BaseButton onClick={onClick}>
    <UserMenuItem title={title} iconName={iconName} />
  </BaseButton>
);
