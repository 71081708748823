import { useState } from "react";
import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { Icon } from "../../../../components/Icon/Icon.tsx";
import { useUser } from "../../../../hooks/useUser.ts";
import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { isUserAllowedTo } from "../../../../types.ts";
import { ExtraMenu } from "../components/ExtraMenu.tsx";
import { useUserCanOpenStyleTagMenu } from "../hooks/useUserCanOpenStyleTagMenu.ts";
import type { Style } from "../types.ts";
import { StyleDeletionDialog } from "./StyleDeletionDialog.tsx";
import { StyleTagMenu } from "./StyleTagMenu.tsx";

export const StyleExtraMenu = ({
  style,
  className,
}: {
  style: Style;
  className: string;
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  const [deletionMenuOpen, setDeletionMenuOpen] = useState(false);
  const [tagMenuOpen, setTagMenuOpen] = useState(false);
  const userCanOpenStyleTagMenu = useUserCanOpenStyleTagMenu();
  const { user } = useUser();
  const { workspace } = useWorkspace();

  const updateStyle = useAppMutation({
    path: "styles/update",
    invalidate: [`workspaces/${workspace.uuid}/styles`],
  }).mutation;

  return (
    <ExtraMenu
      className={className}
      isOpen={isPopoverOpen}
      onOpenChange={setIsPopoverOpen}
      dialog={
        <StyleDeletionDialog
          style={style}
          onDialogChange={setDeletionMenuOpen}
          dialogOpen={deletionMenuOpen}
          onCancel={() => setDeletionMenuOpen(false)}
        />
      }
      popoverContent={
        tagMenuOpen ? (
          <StyleTagMenu style={style} />
        ) : (
          // FIXME: Add button to be able to transfer a personal style to the library
          <div className="flex-col">
            {userCanOpenStyleTagMenu && style.is_curated && (
              <BaseButton
                className="p-200 rounded-100 bg-surface-transparent-primary-rest hover:bg-surface-primary-hover w-full"
                onClick={() => {
                  setTagMenuOpen(true);
                }}
              >
                <div className="gap-400 flex-row text-primary label-lg-default">
                  <Icon name="Tag" />
                  Tag
                </div>
              </BaseButton>
            )}
            {user &&
              isUserAllowedTo(user, "styles:curate") &&
              style.status === "ready" && (
                <BaseButton
                  className="p-200 rounded-100 bg-surface-transparent-primary-rest hover:bg-surface-primary-hover w-full"
                  onClick={() => {
                    setIsPopoverOpen(false);
                    updateStyle.mutate({
                      uuid: style.uuid,
                      is_curated: !style.is_curated,
                    });
                  }}
                >
                  <div className="gap-400 flex-row text-primary label-lg-default">
                    <Icon name="Library" />
                    {!style.is_curated
                      ? "Add to Library"
                      : "Remove from Library"}
                  </div>
                </BaseButton>
              )}
            {!style.is_curated &&
              user &&
              isUserAllowedTo(user, "styles:delete") && (
                <BaseButton
                  className="p-200 rounded-100 bg-surface-transparent-primary-rest hover:bg-surface-primary-hover w-full"
                  onClick={() => {
                    setIsPopoverOpen(false);
                    setDeletionMenuOpen(true);
                  }}
                >
                  <div className="gap-400 flex-row label-lg-default text-primary">
                    <Icon name="Trash" />
                    Delete
                  </div>
                </BaseButton>
              )}
          </div>
        )
      }
    />
  );
};
