import classNames from "classnames";
import { useMemo, useRef, useState } from "react";
import { Button } from "../../../components/Button/Button.tsx";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { Progress } from "../../../components/Progress/Progress.tsx";
import { useImageDropZone } from "../../../hooks/useImageDropZone.ts";
import { useOnMount } from "../../../hooks/useOnMount.ts";
import { useWorkspace } from "../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import type { Style } from "../../types.ts";
import { ModelCreationBackButton } from "../components/ModelCreationBackButton.tsx";
import { ModelCreationCloseButton } from "../components/ModelCreationCloseButton.tsx";
import { StyleCreationImageTile } from "../components/StyleCreationImageTile.tsx";
import { TipsSection } from "../components/TipsSection.tsx";
import { useAddImageOnStyle } from "../hooks/useAddImageOnStyle.ts";

export const TrainingImagesForm = ({
  widthClassName,
  style,
  onClickNext,
}: {
  widthClassName?: string;
  style: Style;
  onClickNext: () => void;
}) => {
  const { workspace } = useWorkspace();
  const [uploadingImages, setUploadingImages] = useState<
    { fileToUpload: File; uuid: string }[]
  >([]);

  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: [`workspaces/${workspace.uuid}/styles`, `styles/${style.uuid}`],
  }).mutation;

  const { getRootProps, getInputProps, open } = useImageDropZone({
    multiple: true,
    minImageSize: 256,
    onImageDrop: (file) => {
      if (file) setUploadingImages((current) => current.concat(file));
    },
  });

  const hasNotEnoughImages = useMemo(
    () => style.training_images.length < 4,
    [style],
  );

  const uploadButtonRef = useRef<HTMLDivElement>(null);
  return (
    // FIXME: find a way to extract the fullscreen drop zone for better modularisation
    <div
      className="h-full relative flex-col-center text-primary"
      {...getRootProps()}
    >
      <ModelCreationBackButton
        onClick={() => {
          updateStyle({
            uuid: style.uuid,
            type: null,
          });
        }}
        className="absolute top-400 left-400"
      />
      <ModelCreationCloseButton className="absolute top-400 right-400" />
      <div className="flex-col w-full flex-fill pt-2000 pb-800 gap-1000 overflow-y-auto items-center">
        <div className="flex-col gap-600">
          <div className="flex-col gap-200 items-center">
            <span className="heading-2xl text-center">
              Upload images to train your model
            </span>
            <span className="body-lg-default text-disabled">
              More images mean greater model precision
            </span>
          </div>
        </div>
        <TipsSection styleType={style.type ? style.type : undefined} />
        <div className={classNames("flex-col gap-800", widthClassName)}>
          {(style.training_images.length > 0 || uploadingImages.length > 0) && (
            <div className="flex-shrink overflow-y-auto">
              <div className="grid lg:grid-cols-3 2xl:grid-cols-4 justify-items-center gap-600 min-w-[120px] w-full">
                {uploadingImages.map((uploadingImage) => {
                  const clearAndScrollToBottom = () => {
                    setUploadingImages((current) =>
                      current.filter((d) => d.uuid !== uploadingImage.uuid),
                    );
                    uploadButtonRef.current?.scrollIntoView({
                      behavior: "smooth",
                    });
                  };
                  return (
                    <TrainingImage
                      key={uploadingImage.uuid}
                      file={uploadingImage.fileToUpload}
                      styleUuid={style.uuid}
                      onUploaded={clearAndScrollToBottom}
                    />
                  );
                })}
                {style.training_images.map((image) => (
                  <StyleCreationImageTile
                    key={image.uuid}
                    innerClassName="rounded-100"
                    className="aspect-square h-[132px] w-[132px]"
                    image={image}
                    styleUuid={style.uuid}
                  />
                ))}
              </div>
            </div>
          )}
          {style.training_images.length > 0 &&
            style.training_images.length < 10 && (
              <div className="flex-row items-center gap-200 self-start">
                <Icon name="Info" className="mb-050" size="sm" />
                <span className="body-md-semibold">
                  More than 10 pictures lead to better results
                </span>
              </div>
            )}
        </div>
        <div
          ref={uploadButtonRef}
          className={classNames(
            "flex-col-center flex-grow gap-400 py-1000 px-800 rounded-300 border-025 border-dashed border-input-border-rest bg-surface-primary-rest max-h-[250px]",
            widthClassName,
          )}
        >
          <input {...getInputProps()} />
          <Icon name="ImagePlus" size="lg" />
          <span className="body-lg-semibold text-primary">
            Drop images here or
          </span>
          <Button type="button" variant="primary" onClick={() => open()}>
            Browse files
          </Button>
        </div>
        <div className="flex-col gap-200 items-center">
          <Button
            className={widthClassName}
            size="md"
            disabled={hasNotEnoughImages}
            onClick={onClickNext}
          >
            Next
          </Button>
          <span className="body-lg-default text-disabled">
            Upload at least 4 pictures to keep going
          </span>
        </div>
      </div>
    </div>
  );
};

const TrainingImage = ({
  file,
  styleUuid,
  onUploaded,
  onSuccess,
}: {
  file: File;
  styleUuid: string;
  onUploaded: () => void;
  onSuccess?: (image_uuid: string) => void;
}) => {
  const [src] = useState(() => URL.createObjectURL(file));
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const { mutate: uploadImage, progress } = useAddImageOnStyle({
    styleUuid,
    onUploaded,
    onSuccess,
    maxSize: 1024,
  });

  useOnMount(() => {
    uploadImage({ image: file });
  });

  return (
    <div className="rounded-100 overflow-hidden aspect-square h-[132px] w-[132px]">
      <Image
        src={src}
        className="opacity-60 h-full w-full"
        imageClassName="m-auto object-cover object-center"
        onLoad={() => setIsImageLoaded(true)}
      />
      {isImageLoaded && <Progress value={progress} />}
    </div>
  );
};
