import classNames from "classnames";
import { useEffect, useState } from "react";
import { BaseButton } from "../../../../../components/Button/BaseButton.tsx";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { CDNImage } from "../../../../../components/Image/CDNImage.tsx";
import { Spinner } from "../../../../../components/Spinner/Spinner.tsx";
import { useAppMutation } from "../../../../../http/useAppMutation.ts";
import { useAppQuery } from "../../../../../http/useAppQuery.ts";
import { useBoard } from "../../../hooks/useBoard.ts";
import { useSelectedAsset } from "../../../hooks/useSelectedAsset.ts";
import { useUpdateAsset } from "../../../hooks/useUpdateAsset.ts";
import type { AssetHistory, ImageGenerationModelType } from "../../../types.ts";
import { overlayDisplayedContentUuidStore } from "../../EditWorkspace/stores/overlayDisplayedContentUuidStore.ts";
import { RightDetailsLayout } from "../RightDetailsLayout.tsx";

export const EditionHistory = ({
  onCloseButtonClick,
}: {
  onCloseButtonClick: () => void;
}) => {
  const { selectedAsset } = useSelectedAsset();

  const [selectedEditionIterationUuid, setSelectedEditionIterationUuid] =
    useState<string | null | undefined>(undefined);

  const { data: editionHistory } = useAppQuery<AssetHistory>({
    queryKey: selectedAsset?.uuid
      ? `assets/${selectedAsset.uuid}/history`
      : null,
    enabled: !!selectedAsset,
  });

  // XXX: When opening component we automatically select the edition iteration corresponding to the asset content
  // displayed if none already selected
  useEffect(() => {
    if (selectedEditionIterationUuid) return;
    if (editionHistory?.original_content.uuid === selectedAsset?.content.uuid) {
      setSelectedEditionIterationUuid(editionHistory?.original_content.uuid);
    }
    if (editionHistory) {
      for (const editionIteration of editionHistory.versions) {
        if (
          editionIteration.variants.some(
            (it) => it.uuid === selectedAsset?.content.uuid,
          )
        ) {
          setSelectedEditionIterationUuid(editionIteration.uuid);
        }
      }
    }
  }, [
    selectedAsset,
    editionHistory,
    selectedEditionIterationUuid,
    setSelectedEditionIterationUuid,
  ]);

  useEffect(() => {
    if (editionHistory?.original_content.uuid === selectedAsset?.content.uuid) {
      setSelectedEditionIterationUuid(editionHistory?.original_content.uuid);
    }
    if (editionHistory) {
      for (const editionIteration of editionHistory.versions) {
        if (
          editionIteration.variants.some(
            (it) => it.uuid === selectedAsset?.content.uuid,
          )
        ) {
          setSelectedEditionIterationUuid(editionIteration.uuid);
        }
      }
    }
    // XXX: We automatically unfold the category of the selected asset content when this one changes.
  }, [selectedAsset, editionHistory]);

  const updateAsset = useUpdateAsset();

  useEffect(() => {
    overlayDisplayedContentUuidStore.clearOverlayDisplayedContentUuid();
    return () =>
      overlayDisplayedContentUuidStore.clearOverlayDisplayedContentUuid();
  }, []);

  return (
    editionHistory &&
    selectedAsset && (
      <RightDetailsLayout
        name="Edition history"
        onCloseButtonClick={onCloseButtonClick}
      >
        <div className="flex-col gap-100 p-300">
          {editionHistory.versions
            .sortDesc((it) => it.created_at)
            .map((editionIteration, index) => (
              <div
                key={index}
                className={classNames(
                  selectedEditionIterationUuid === editionIteration.uuid
                    ? "bg-surface-primary-hover"
                    : "",
                  "flex-col rounded-200 overflow-clip",
                )}
              >
                <BaseButton
                  className={classNames(
                    "flex-row hover:bg-surface-primary-hover gap-200 p-100",
                  )}
                  onClick={() => {
                    setSelectedEditionIterationUuid(editionIteration.uuid);
                  }}
                >
                  <EditionImageThumbnail
                    active={editionIteration.variants.some(
                      (it) => it.uuid === selectedAsset.content.uuid,
                    )}
                    url={editionIteration.variants[0]?.url}
                  />
                  <EditionIterationName
                    iterationName={`Edit ${(
                      editionHistory.versions.length - index
                    )
                      .toString()
                      .padStart(2, "0")}`}
                    generationType={editionIteration.generation_type}
                  />
                </BaseButton>
                {selectedEditionIterationUuid === editionIteration.uuid &&
                  editionIteration.variants.map((content, contentIndex) => (
                    <div
                      key={content.uuid}
                      className={classNames(
                        selectedAsset.content.uuid === content.uuid &&
                          "bg-surface-primary-active",
                        "group flex-row justify-between items-center hover:bg-surface-primary-active p-100",
                      )}
                      onMouseEnter={() => {
                        overlayDisplayedContentUuidStore.setOverlayDisplayedContentUuid(
                          content.uuid,
                        );
                      }}
                      onMouseLeave={() => {
                        overlayDisplayedContentUuidStore.clearOverlayDisplayedContentUuid();
                      }}
                    >
                      <BaseButton onClick={() => updateAsset(content.uuid)}>
                        <div className="flex-row gap-200 items-center">
                          <EditionImageThumbnail
                            url={content.url}
                            active={selectedAsset.content.uuid === content.uuid}
                          />
                          <div className="flex-col ">
                            {/* FIXME: We should have better name for the variations */}
                            <div className="body-md-semibold text-primary">
                              Variant{" "}
                              {(contentIndex + 1).toString().padStart(2, "0")}
                            </div>
                          </div>
                        </div>
                      </BaseButton>
                      <div
                        className={classNames(
                          selectedAsset.content.uuid === content.uuid
                            ? "visible"
                            : "invisible group-hover:visible",
                          "flex-row items-center",
                        )}
                      >
                        <CopyContentAsAssetButton contentUuid={content.uuid} />
                      </div>
                    </div>
                  ))}
              </div>
            ))}
          <div
            className={classNames(
              selectedEditionIterationUuid ===
                editionHistory.original_content.uuid
                ? "bg-surface-primary-active"
                : "hover:bg-surface-primary-hover",
              "group flex-row rounded-200 justify-between p-100",
            )}
            onMouseEnter={() => {
              overlayDisplayedContentUuidStore.setOverlayDisplayedContentUuid(
                editionHistory.original_content.uuid,
              );
            }}
            onMouseLeave={() => {
              overlayDisplayedContentUuidStore.clearOverlayDisplayedContentUuid();
            }}
          >
            <BaseButton
              onClick={() => {
                setSelectedEditionIterationUuid(
                  editionHistory.original_content.uuid,
                );
                updateAsset(editionHistory.original_content.uuid);
              }}
            >
              <div className="flex-row items-center gap-200">
                <EditionImageThumbnail
                  url={editionHistory.original_content.url}
                  active={
                    selectedAsset.content.uuid ===
                    editionHistory.original_content.uuid
                  }
                />
                <EditionIterationName
                  iterationName="Original"
                  generationType={editionHistory.original_generation_type}
                />
              </div>
            </BaseButton>
            <div
              className={classNames(
                "flex-col-center",
                selectedAsset.content.uuid ===
                  editionHistory.original_content.uuid
                  ? "visible"
                  : "invisible group-hover:visible",
              )}
            >
              <CopyContentAsAssetButton
                contentUuid={editionHistory.original_content.uuid}
              />
            </div>
          </div>
        </div>
      </RightDetailsLayout>
    )
  );
};

const EditionIterationName = ({
  iterationName,
  generationType,
}: {
  iterationName: string;
  generationType: ImageGenerationModelType | null;
}) => (
  <div className="flex-col ">
    <div className="body-md-semibold text-secondary">{iterationName}</div>
    <div className="body-md-default text-secondary">
      {generationType === "text_to_image"
        ? "Generate"
        : generationType === "image_to_image"
        ? "Variations"
        : generationType === "style_transfer"
        ? "Style Transfer"
        : generationType === "upscaler"
        ? "Upscale"
        : generationType === "generative_fill"
        ? "Fill"
        : generationType === "brief_to_image"
        ? "Brief"
        : "Other"}
    </div>
  </div>
);

// FIXME: We must create a basic component similar to ImageTile that could be reused everywhere
const EditionImageThumbnail = ({
  url,
  active = false,
}: {
  url: string | null;
  active?: boolean;
}) =>
  url ? (
    <CDNImage
      src={url}
      className={classNames(
        active && "border-input-border-active border-025",
        "h-800 w-800 rounded-150",
      )}
      imageClassName="object-cover h-full w-full"
      srcDimension="thumbnail64"
    />
  ) : (
    <div
      className={classNames(
        active && "border-input-border-active border-025",
        "flex bg-surface-secondary-active h-800 w-800 rounded-150 items-center justify-center",
      )}
    >
      <Spinner size="sm" />
    </div>
  );

const CopyContentAsAssetButton = ({ contentUuid }: { contentUuid: string }) => {
  const { board } = useBoard();

  // FIXME: When creating asset from existing content, we duplicate also the whole history of the asset. This could be
  //  fixed by saving in the asset table an original content from which computing the history.
  const { mutate: createAsset } = useAppMutation({
    path: "assets/create",
    invalidate: [`boards/${board.uuid}`],
  }).mutation;

  return (
    <ClickableIcon
      name="CopyPlus"
      variant="tertiary"
      size="sm"
      tooltip={{
        side: "bottom",
        content: "Save copy as new asset",
      }}
      onClick={() =>
        createAsset({
          board_uuid: board.uuid,
          content_uuid: contentUuid,
        })
      }
    />
  );
};
