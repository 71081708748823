import { useState } from "react";
import { UrlAnimation } from "../../../../../components/Animation/UrlAnimation.tsx";
import { Badge } from "../../../../../components/Badge/Badge.tsx";
import { BaseButton } from "../../../../../components/Button/BaseButton.tsx";
import { Button } from "../../../../../components/Button/Button.tsx";
import { ClickableIcon } from "../../../../../components/Icon/ClickableIcon.tsx";
import { Icon } from "../../../../../components/Icon/Icon.tsx";
import { CDNImage } from "../../../../../components/Image/CDNImage.tsx";
import { Popover } from "../../../../../components/Popover/Popover.tsx";
import { Tooltip } from "../../../../../components/Tooltip/Tooltip.tsx";
import { ModelDetails } from "../../../../components/ModelDetails/ModelDetails.tsx";
import { StylePicker } from "../../../../components/StylePicker/StylePicker.tsx";
import { GENERIC_STYLE_UUID } from "../../../../constants.ts";
import type { Style } from "../../../../Home/HomeIndex/types.ts";
import type { StyleType } from "../../../../types.ts";
import { useBoard } from "../../../hooks/useBoard.ts";
import { useRetrainStyle } from "../../../hooks/useRetrainStyle.ts";
import {
  DEFAULT_LORA_SCALE,
  useSelectedStylesGenerationParams,
  useSelectedStylesGenerationParamsStore,
} from "../../../hooks/useSelectedStylesGenerationParams.ts";
import { getSelectedTool } from "../../../utils/getSelectedTool.ts";
import {
  SettingsSectionLayout,
  SettingsSectionWrapper,
} from "./SettingsSectionWrapper.tsx";
import { SliderSection } from "./SliderSection.tsx";

export const StylesSelectionSection = () => {
  const { board } = useBoard();
  const [styleSelectorFilter, setStyleSelectorFilter] = useState<
    StyleType | undefined
  >(undefined);
  const [isStyleSelectorOpen, setIsStyleSelectorOpen] =
    useState<boolean>(false);
  const [activeStyleUuid, setActiveStyleUuid] = useState<string | undefined>(
    undefined,
  );
  const [isAddStyleMenuOpen, setIsAddStyleMenuOpen] = useState<boolean>(false);

  const {
    selectedStyles,
    selectedStylesGenerationParams,
    isMixCompatibleWithCurrentTool,
  } = useSelectedStylesGenerationParams();

  const tool = getSelectedTool();

  return (
    <>
      {selectedStylesGenerationParams.length ? (
        <SettingsSectionWrapper
          // FIXME: The name used does not fit the current design and must be harmonized later
          name="Models selection"
          rightActionButton={
            <Popover
              side="right"
              align="start"
              isOpen={isAddStyleMenuOpen}
              onOpenChange={(open) => {
                setIsAddStyleMenuOpen(open);
              }}
              content={
                <AddStyleMenu
                  onClick={(styleType) => {
                    setStyleSelectorFilter(styleType);
                    setActiveStyleUuid(undefined);
                    setIsStyleSelectorOpen(true);
                    setIsAddStyleMenuOpen(false);
                  }}
                />
              }
            >
              {/* FIXME Add tooltip */}
              <ClickableIcon
                name="Plus"
                size="sm"
                variant="tertiary"
                disabled={
                  selectedStylesGenerationParams.length >
                  (tool === "generate" ? 1 : 0)
                }
                onClick={() => {
                  setActiveStyleUuid(undefined);
                  setIsAddStyleMenuOpen(!isAddStyleMenuOpen);
                }}
                tooltip={{
                  side: "right",
                  content: isMixCompatibleWithCurrentTool
                    ? selectedStylesGenerationParams.length > 1
                      ? "You can only mix two models"
                      : "Add a model. You can mix a style model with a character or an object model"
                    : selectedStylesGenerationParams.length > 0
                    ? "You cannot mix models in this tool"
                    : "Add a style, character or object model",
                }}
              />
            </Popover>
          }
          content={
            <div className="flex-col w-full gap-300">
              {selectedStyles.map((style) => (
                <StyleSelectionButton
                  key={style.uuid}
                  style={style}
                  onClick={() => {
                    setActiveStyleUuid(style.uuid);
                    setStyleSelectorFilter(style.type ?? undefined);
                    setIsStyleSelectorOpen(true);
                  }}
                  onRemove={() => {
                    setActiveStyleUuid(undefined);
                    setStyleSelectorFilter(undefined);
                    useSelectedStylesGenerationParamsStore.removeStyleFromSelection(
                      {
                        styleUuid: style.uuid,
                        boardUuid: board.uuid,
                      },
                    );
                  }}
                />
              ))}
            </div>
          }
        />
      ) : (
        <EmptyStyleSelectionButtonSection
          onClick={() => setIsStyleSelectorOpen(true)}
        />
      )}
      <StylePicker
        dialogOpen={isStyleSelectorOpen}
        onDialogChange={setIsStyleSelectorOpen}
        onStyleSelect={(styleUuid) => {
          if (activeStyleUuid && activeStyleUuid !== styleUuid) {
            useSelectedStylesGenerationParamsStore.removeStyleFromSelection({
              styleUuid: activeStyleUuid,
              boardUuid: board.uuid,
            });
          }
          useSelectedStylesGenerationParamsStore.addStyleToSelection({
            styleUuid,
            boardUuid: board.uuid,
          });
          setIsStyleSelectorOpen(false);
        }}
        styleTypeFilter={styleSelectorFilter}
        selectionButtonName={
          styleSelectorFilter
            ? styleSelectorFilter === "character"
              ? "Use character"
              : styleSelectorFilter === "object"
              ? "Use object"
              : "Use style"
            : "Use model in project"
        }
        disableNonMixableStyles={
          activeStyleUuid === undefined &&
          selectedStylesGenerationParams.length > 0
        }
      />
    </>
  );
};

const StyleSelectionButton = ({
  style,
  onClick,
  onRemove,
}: {
  style: Style;
  onClick: () => void;
  onRemove: () => void;
}) => {
  const [isHovering, setIsHovering] = useState(false);
  const [isSettingsMenuOpen, setIsSettingsMenuOpen] = useState(false);
  const { selectedStylesGenerationParams, isMixCompatibleWithCurrentTool } =
    useSelectedStylesGenerationParams();

  const isModelUsable =
    (isMixCompatibleWithCurrentTool &&
      style.is_mixable &&
      selectedStylesGenerationParams.length > 1) ||
    selectedStylesGenerationParams.length < 2;

  const { styleRetrainStatus, isUserAllowed } = useRetrainStyle({
    styleUuid: style.uuid,
  });

  return (
    // FIXME: The design of this component is not final and could be improved
    <div className="flex-row items-center w-full gap-100 ">
      <div
        className="w-full flex-fill"
        onMouseEnter={() => {
          setIsHovering(true);
        }}
        onMouseLeave={() => {
          setIsHovering(false);
        }}
      >
        <div className="flex-row flex-fill items-center h-800 px-100 gap-150 rounded-150 border border-input-border-rest hover:border-input-border-hover text-primary label-md-semibold hover:bg-input-surface-hover bg-input-surface-rest">
          <div className="flex-row-center w-500 h-800">
            {isHovering ? (
              <ClickableIcon
                name="X"
                size="sm"
                variant="tertiary"
                onClick={onRemove}
                tooltip={{ content: "Remove the model" }}
              />
            ) : (
              <div className="h-500 w-500 rounded-100 overflow-clip border-input-border-width">
                {style.thumbnail_url ? (
                  <CDNImage
                    src={style.thumbnail_url}
                    className="w-full h-full"
                    imageClassName="h-full w-full object-cover object-center"
                    srcDimension="thumbnail64"
                  />
                ) : style.uuid === GENERIC_STYLE_UUID ? (
                  <div className="w-full h-full flex-row-center bg-surface-emphasis-primary-rest">
                    <Icon
                      size="sm"
                      name="PimentoStar"
                      className="fill-button-primary-rest"
                    />
                  </div>
                ) : (
                  <div className="flex-col-center h-full w-full bg-surface-secondary-rest" />
                )}
              </div>
            )}
          </div>
          <Tooltip content="Replace the model">
            <BaseButton
              className="flex-row flex-fill items-center"
              onClick={onClick}
            >
              <div className="flex-fill truncate w-[70%]">
                {style.name ? style.name : "Untitled"}
              </div>
              <Icon
                size="sm"
                name="ChevronsUpDown"
                className="fill-button-primary-rest"
              />
            </BaseButton>
          </Tooltip>
        </div>
      </div>
      <Popover
        side="right"
        align="end"
        isOpen={isSettingsMenuOpen}
        onOpenChange={(open) => {
          setIsSettingsMenuOpen(open);
        }}
        content={
          <StyleSettingsMenu
            style={style}
            onClose={() => setIsSettingsMenuOpen(false)}
            onClick={onClick}
            isModelUsable={isModelUsable}
            onRemove={onRemove}
          />
        }
      >
        {/* FIXME: We must improve base icon component to deal with more inputs */}
        <Button variant="tertiary" className="aspect-square p-0" size="sm">
          <Icon
            name={
              !isMixCompatibleWithCurrentTool &&
              selectedStylesGenerationParams.length > 1
                ? "TriangleAlert"
                : isModelUsable
                ? "Settings2"
                : styleRetrainStatus === "retrainable" && isUserAllowed
                ? "RefreshCcw"
                : styleRetrainStatus === "training"
                ? "LoaderCircle"
                : "TriangleAlert"
            }
            size="sm"
            className={
              !isMixCompatibleWithCurrentTool &&
              selectedStylesGenerationParams.length > 1
                ? "stroke-error-rest"
                : isModelUsable || styleRetrainStatus === "training"
                ? "stroke-primary-rest"
                : "stroke-error-rest"
            }
          />
        </Button>
      </Popover>
    </div>
  );
};

const EmptyStyleSelectionButtonSection = ({
  onClick,
}: {
  onClick: () => void;
}) => (
  <SettingsSectionLayout>
    <div className="flex-col-center gap-300 p-300 bg-primary label-md-semibold text-primary rounded-300">
      <div className="flex-row-center items-center">
        <div className="relative h-1000 w-800">
          <div
            style={{
              transform: "translateY(20%) translateX(80%) rotate(15deg)",
            }}
            className="absolute bg-surface-primary-rest h-800 w-800 flex-col-center items-center shadow-100 rounded-150"
          >
            <Icon size="sm" name="PersonStanding" />
          </div>
          <div
            style={{
              transform: "translateY(20%) translateX(-80%) rotate(-15deg)",
            }}
            className="absolute bg-surface-primary-rest h-800 w-800 flex-col-center items-center shadow-100 rounded-150"
          >
            <Icon size="sm" name="Shirt" />
          </div>
          <div className="relative bg-surface-primary-rest h-800 w-800 flex-col-center items-center shadow-200 rounded-150">
            <Icon size="sm" name="Droplets" />
          </div>
        </div>
      </div>
      <Button size="sm" variant="primary" onClick={onClick} disclosure>
        Add new
      </Button>
      <div className="text-center">Add styles, characters and objects.</div>
    </div>
  </SettingsSectionLayout>
);

const AddStyleMenu = ({
  onClick,
}: {
  onClick: (styleType: StyleType) => void;
}) => {
  const { selectedStyles } = useSelectedStylesGenerationParams();

  const isCharacterDisabled =
    selectedStyles.map((it) => it.type).includes("character") ||
    selectedStyles.map((it) => it.type).includes("object");
  const isObjectDisabled =
    selectedStyles.map((it) => it.type).includes("character") ||
    selectedStyles.map((it) => it.type).includes("object");
  const isStyleDisabled = selectedStyles.map((it) => it.type).includes("style");

  return (
    <div className="flex-col label-md-default p-200">
      <BaseButton
        className="flex-row items-center gap-200 px-200 py-100 rounded-200 disabled:text-disabled disabled:stroke-button-primary-disable enabled:hover:bg-surface-primary-hover"
        disabled={isStyleDisabled}
        onClick={() => onClick("style")}
      >
        <Icon name="Droplets" size="sm" />
        <div> Add style</div>
      </BaseButton>
      <BaseButton
        className="flex-row items-center gap-200 px-200 py-100 rounded-200 disabled:text-disabled disabled:stroke-button-primary-disable enabled:hover:bg-surface-primary-hover"
        onClick={() => onClick("character")}
        disabled={isCharacterDisabled}
      >
        <Icon name="PersonStanding" size="sm" />
        <div> Add character</div>
      </BaseButton>
      <BaseButton
        className="flex-row items-center gap-200 px-200 py-100 rounded-200 disabled:text-disabled disabled:stroke-button-primary-disable enabled:hover:bg-surface-primary-hover"
        onClick={() => onClick("object")}
        disabled={isObjectDisabled}
      >
        <Icon name="Shirt" size="sm" />
        <div> Add object</div>
        <Badge size="sm" label="Beta" color="emphasisSecondary" />
      </BaseButton>
    </div>
  );
};

const StyleSettingsMenu = ({
  style,
  onClose,
  isModelUsable,
  onClick,
  onRemove,
}: {
  style: Style;
  onClose: () => void;
  isModelUsable: boolean;
  onClick?: () => void;
  onRemove?: () => void;
}) => {
  const { selectedStylesGenerationParams, isMixCompatibleWithCurrentTool } =
    useSelectedStylesGenerationParams();
  const { board } = useBoard();

  const loraScale = selectedStylesGenerationParams.find(
    (it) => it.uuid === style.uuid,
  )?.scale;

  const {
    retrain,
    isLoading,
    isDisabled: isRetrainDisabled,
    isUserAllowed,
    styleRetrainStatus,
  } = useRetrainStyle({ styleUuid: style.uuid });

  return (
    loraScale !== undefined && (
      <div className="flex-col w-[400px] max-h-[100vh]">
        <div className="flex-row items-center w-full justify-between p-200">
          {/* FIXME: The current implementation is a simplification of the current design */}
          <div className="flex-row w-[160px] items-center h-800 px-100 gap-150 rounded-150 text-primary label-md-semibold">
            <div className="flex-row-center w-500 h-800">
              <div className="h-500 w-500 rounded-100 overflow-clip border-input-border-width">
                {style.thumbnail_url ? (
                  <CDNImage
                    src={style.thumbnail_url}
                    className="w-full h-full"
                    imageClassName="h-full w-full object-cover object-center"
                    srcDimension="thumbnail64"
                  />
                ) : style.uuid === GENERIC_STYLE_UUID ? (
                  <div className="w-full h-full flex-row-center bg-surface-emphasis-primary-rest">
                    <Icon
                      size="sm"
                      name="PimentoStar"
                      className="fill-button-primary-rest"
                    />
                  </div>
                ) : (
                  <div className="flex-col-center h-full w-full bg-surface-secondary-rest" />
                )}
              </div>
            </div>
            <div className="flex-fill truncate w-[70%]">
              {style.name ? style.name : "Untitled"}
            </div>
          </div>
          <ClickableIcon
            name="X"
            size="sm"
            variant="tertiary"
            onClick={onClose}
          />
        </div>
        {isModelUsable ? (
          <>
            <div className="flex-col p-300 px-200 bg-primary border-y border-primary-rest">
              <SliderSection
                value={Math.round(loraScale * 100)}
                min={0}
                max={100}
                onChange={(loraScalePercentage) =>
                  useSelectedStylesGenerationParamsStore.updateStyleLoraScale({
                    styleUuid: style.uuid,
                    scale: loraScalePercentage / 100,
                    boardUuid: board.uuid,
                  })
                }
                sliderName="Model intensity"
                defaultValue={DEFAULT_LORA_SCALE * 100}
                sliderInformationSection={
                  <div className="flex-col min-h-[260px] py-100 gap-200 text-primary body-md-default">
                    <div>
                      Higher style intensity leads to generations closer to your
                      style images.
                    </div>
                    <UrlAnimation url="https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/0fce170e-234c-479b-99f9-c25642c628ef.json" />
                  </div>
                }
              />
            </div>
            <div className="flex-fill p-300 overflow-auto">
              <ModelDetails styleUuid={style.uuid} />
            </div>
          </>
        ) : !isMixCompatibleWithCurrentTool &&
          selectedStylesGenerationParams.length > 1 ? (
          <div className="p-300 w-full">
            <div className="flex-col-center p-300 gap-300 bg-primary rounded-300">
              <Icon name="TriangleAlert" size="sm" />
              <div className="label-sm-semibold text-primary">
                Multiple model not available with this tool
              </div>
              <div className="label-sm-default text-secondary text-center">
                Unselect one of the selected model
              </div>
              <Button variant="secondary" size="sm" onClick={onRemove}>
                Unselect this model
              </Button>
            </div>
          </div>
        ) : styleRetrainStatus === "retrainable" && isUserAllowed ? (
          <div className="p-300 w-full">
            <div className="flex-col-center p-300 gap-300 bg-primary rounded-300">
              <Icon name="RefreshCcw" size="sm" />
              <div className="label-sm-semibold text-primary">
                This model needs to be retrained
              </div>
              <div className="label-sm-default text-secondary">
                Retraining takes around 15 min
              </div>
              <Button
                variant="primary"
                size="sm"
                onClick={retrain}
                disabled={isRetrainDisabled}
                loading={isLoading}
              >
                Retrain model
              </Button>
            </div>
          </div>
        ) : styleRetrainStatus === "training" ? (
          <div className="p-300 w-full">
            <div className="flex-col-center p-300 gap-300 bg-primary rounded-300">
              <Icon name="LoaderCircle" size="sm" />
              <div className="label-sm-semibold text-primary">
                Model is training
              </div>
              <div className="label-sm-default text-secondary text-center">
                You’ll be able to use it in around 15min. We will send you an
                email when it’s ready
              </div>
            </div>
          </div>
        ) : (
          <div className="p-300 w-full">
            <div className="flex-col-center p-300 gap-300 bg-primary rounded-300">
              <Icon name="TriangleAlert" size="sm" />
              <div className="label-sm-semibold text-primary">
                You can't mix this style yet
              </div>
              <div className="label-sm-default text-secondary text-center">
                Select another one
              </div>
              <Button variant="primary" size="sm" onClick={onClick}>
                Change model
              </Button>
            </div>
          </div>
        )}
      </div>
    )
  );
};
