import classNames from "classnames";
import { useState } from "react";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Button } from "../../../components/Button/Button.tsx";
import { Dialog } from "../../../components/Dialog/Dialog.tsx";
import { ClickableIcon } from "../../../components/Icon/ClickableIcon.tsx";
import { Icon, type IconName } from "../../../components/Icon/Icon.tsx";
import { Popover } from "../../../components/Popover/Popover.tsx";
import { Spinner } from "../../../components/Spinner/Spinner.tsx";
import { TextField } from "../../../components/TextField/TextField.tsx";
import { useIsUserWorkspaceOwner } from "../../../hooks/useIsUserWorkspaceOwner.ts";
import { useUser } from "../../../hooks/useUser.ts";
import { useWorkspace } from "../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import {
  isUserAllowedTo,
  type User,
  type UserWorkspace,
  type WorkspaceDetails,
} from "../../../types.ts";
import { useCreateCustomerPortalUrl } from "../HomeIndex/hooks/useCreateCustomerPortalUrl.ts";
import { AddUsersDialog } from "./AddUsersDialog.tsx";

const MAIL_TO = "mailto:contact@pimento.design";

export const ProfileSettingsDialog = ({
  isOpen,
  onOpenChange,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}) => (
  <Dialog
    title="Settings"
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    content={<ProfileSettingsDialogContent onOpenChange={onOpenChange} />}
  />
);

const ProfileSettingsDialogContent = ({
  onOpenChange,
}: {
  onOpenChange: (open: boolean) => void;
}) => {
  const { user } = useUser();
  const { workspace } = useWorkspace();

  return (
    user && (
      <ProfileSettings
        user={user}
        workspace={workspace}
        onUpdate={() => onOpenChange(false)}
      />
    )
  );
};

const ProfileSettings = ({
  user,
  workspace,
  onUpdate,
}: {
  user: User;
  workspace: WorkspaceDetails | undefined;
  onUpdate: () => void;
}) => {
  const { createCustomerPortalUrl, isCreateCustomerPortalUrlLoading } =
    useCreateCustomerPortalUrl();
  const [currentTab, setCurrentTab] = useState(
    "profile" as "profile" | "general" | "members",
  );
  const isUserWorkspaceOwner = useIsUserWorkspaceOwner();
  return (
    <div className="h-[528px] w-[704px] flex-row divide-x-025 divide-solid divide-primary-rest">
      <div className="w-[168px] flex-col pt-300">
        <div className="px-200">
          <div>
            <NavPrimaryItem title="Account" icon="User" />
            <NavSecondaryItem
              title="Profile"
              onClick={() => setCurrentTab("profile")}
              isSelected={currentTab === "profile"}
            />
          </div>
          {workspace &&
            isUserAllowedTo(user, "mode:debug") &&
            isUserWorkspaceOwner && (
              <div>
                <NavPrimaryItem title="Workspace" icon="Building" />
                <NavSecondaryItem
                  title="General"
                  onClick={() => setCurrentTab("general")}
                  isSelected={currentTab === "general"}
                />
                <NavSecondaryItem
                  title="Members"
                  onClick={() => setCurrentTab("members")}
                  isSelected={currentTab === "members"}
                />
                <NavSecondaryItem
                  title="Plan & billing"
                  icon="ExternalLink"
                  onClick={() => {
                    createCustomerPortalUrl({ workspace_uuid: workspace.uuid });
                  }}
                  isSelected={isCreateCustomerPortalUrlLoading}
                  isLoading={isCreateCustomerPortalUrlLoading}
                />
              </div>
            )}
        </div>
      </div>
      <div className="pt-400 flex-col flex-fill">
        {currentTab === "profile" ? (
          <AccountProfileSettings user={user} onUpdate={onUpdate} />
        ) : workspace && currentTab === "general" ? (
          <WorkspaceGeneralSettings workspace={workspace} onUpdate={onUpdate} />
        ) : workspace && currentTab === "members" ? (
          <WorkspaceMembersSettings user={user} workspace={workspace} />
        ) : null}
      </div>
    </div>
  );
};

const AccountProfileSettings = ({
  user,
  onUpdate,
}: {
  user: User;
  onUpdate: () => void;
}) => {
  const [firstName, setFirstName] = useState(user.first_name);
  const [lastName, setLastName] = useState(user.last_name);
  const [phoneNumber, setPhoneNumber] = useState(user.phone_number);

  const {
    mutate: userInformationMutate,
    isLoading: isUserInformationMutationLoading,
  } = useAppMutation({
    path: "users/add-information",
    invalidate: ["users/me"],
  }).mutation;
  return (
    <div className="px-400 flex-col divide-y-025 divide-solid divide-primary-rest">
      <div className="pb-400">
        <div className="py-200 label-lg-semibold text-primary">Profile</div>
      </div>
      <div className="py-400 flex-col">
        <SettingsTextItem
          title="First name"
          value={firstName}
          onChange={setFirstName}
        />
        <SettingsTextItem
          title="Last name"
          value={lastName}
          onChange={setLastName}
        />
        <SettingsTextItem
          title="Phone number"
          value={phoneNumber}
          onChange={setPhoneNumber}
          type="number"
        />
        <div className="py-200 flex-row items-center justify-end gap-200">
          <Button
            size="md"
            variant="secondary"
            loading={isUserInformationMutationLoading}
            disabled={
              !firstName ||
              !lastName ||
              (user.first_name === firstName &&
                user.last_name === lastName &&
                user.phone_number === phoneNumber)
            }
            onClick={() =>
              userInformationMutate(
                {
                  first_name: firstName,
                  last_name: lastName,
                  phone_number: phoneNumber === "" ? null : phoneNumber,
                },
                {
                  onSuccess: onUpdate,
                },
              )
            }
          >
            Save changes
          </Button>
        </div>
      </div>
      <div className="py-400 flex-col">
        <SettingsClickableItem
          title="Email"
          value={user.email}
          buttonLink={MAIL_TO + "?subject=Change my Pimento email"}
          buttonText="Request email update"
        />
        <SettingsClickableItem
          title="Password"
          value="Only you know"
          buttonLink={MAIL_TO + "?subject=Change my Pimento password"}
          buttonText="Request password update"
        />
      </div>
      <div className="pt-400">
        <a
          className="body-md-semibold text-error-secondary underline"
          href={MAIL_TO + "?subject=Delete my Pimento account"}
          target="_blank"
        >
          Request account deletion
        </a>
      </div>
    </div>
  );
};

const WorkspaceGeneralSettings = ({
  workspace,
  onUpdate,
}: {
  workspace: WorkspaceDetails;
  onUpdate: () => void;
}) => {
  const [name, setName] = useState(workspace.name);
  const {
    mutate: workspaceInformationMutate,
    isLoading: isWorkspaceInformationMutationLoading,
  } = useAppMutation({
    path: `workspaces/${workspace.uuid}`,
    invalidate: [`workspaces/${workspace.uuid}`, "users/me"],
  }).mutation;

  return (
    <div className="px-400 flex-col divide-y-025 divide-solid divide-primary-rest">
      <div className="pb-400">
        <div className="py-200 label-lg-semibold text-primary">Profile</div>
      </div>
      <div className="py-400 flex-col">
        <SettingsTextItem title="Name" value={name} onChange={setName} />
        <div className="py-200 flex-row items-center justify-end gap-200">
          <Button
            size="md"
            variant="secondary"
            loading={isWorkspaceInformationMutationLoading}
            disabled={!name || workspace.name === name}
            onClick={() =>
              workspaceInformationMutate(
                {
                  name,
                },
                {
                  onSuccess: onUpdate,
                },
              )
            }
          >
            Save changes
          </Button>
        </div>
      </div>
      <div className="pt-400">
        <a
          className="body-md-semibold text-error-secondary underline"
          href={MAIL_TO + "?subject=Delete my workspace"}
          target="_blank"
        >
          Request workspace deletion
        </a>
      </div>
    </div>
  );
};

const WorkspaceMembersSettings = ({
  workspace,
  user,
}: {
  workspace: WorkspaceDetails;
  user: User;
}) => {
  const { createCustomerPortalUrl, isCreateCustomerPortalUrlLoading } =
    useCreateCustomerPortalUrl();
  const [isAddUserDialogOpen, setIsAddUsersDialogOpen] = useState(false);

  const removeUser = useAppMutation({
    path: "workspaces/remove-user",
    invalidate: [`workspaces/${workspace.uuid}`],
  }).mutation;

  const removeUserFromWorkspace = (userUuid: string) => {
    removeUser.mutate({ workspace_uuid: workspace.uuid, user_uuid: userUuid });
  };

  return (
    <>
      <div className="px-400 w-full flex-col divide-y-025 divide-solid divide-primary-rest">
        <div className="pb-400 w-full">
          <div className="py-200 h-[42px] flex-row-center overflow-visible">
            <div className="flex-col flex-fill gap-[3px]">
              <div className="label-lg-semibold text-primary">Members</div>
              {workspace.num_seats ? (
                <div className="label-md-semibold text-secondary">
                  {workspace.num_seats - workspace.num_members}/
                  {workspace.num_seats} available seats
                </div>
              ) : (
                <div className="label-md-semibold text-secondary">
                  Unlimited seats
                </div>
              )}
            </div>
            {isUserAllowedTo(user, "subscription:view") && (
              <div className="flex-row gap-200">
                <Button
                  size="md"
                  variant="secondary"
                  onClick={() => {
                    createCustomerPortalUrl({ workspace_uuid: workspace.uuid });
                  }}
                >
                  <div className="flex-row-center gap-050">
                    {isCreateCustomerPortalUrlLoading ? (
                      <Spinner size="xs" />
                    ) : (
                      <Icon name="ExternalLink" size="md" />
                    )}
                    <div>Add more seats </div>
                  </div>
                </Button>
                <Button
                  size="md"
                  variant="primary"
                  onClick={() => {
                    setIsAddUsersDialogOpen(true);
                  }}
                >
                  Invite members
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className="flex-col-center divide-y-025 divide-solid divide-primary-rest">
          <div key="header" className="w-full h-800 flex-row items-center">
            <div className="w-[368px] flex-row pr-200 label-sm-semibold text-disabled">
              User
            </div>
            <div className="w-[104px] flex-row-reverse px-200 label-sm-semibold text-disabled">
              Role
            </div>
            <div className="w-800 h-full" />
          </div>
          {workspace.users.map((item) => (
            <UserItem
              key={item.uuid}
              user={item}
              onRemoveClick={removeUserFromWorkspace}
            />
          ))}
        </div>
      </div>
      <AddUsersDialog
        isOpen={isAddUserDialogOpen}
        onOpenChange={(open) => setIsAddUsersDialogOpen(open)}
      />
    </>
  );
};

const SettingsTextItem = ({
  title,
  value,
  onChange,
  type = "text",
}: {
  title: string;
  value: string | null;
  onChange: (value: string) => void;
  type?: "text" | "number";
}) => (
  <div className="py-200 flex-row justify-between items-center gap-300">
    <div className="body-md-semibold text-primary">{title}</div>
    <TextField
      size="md"
      value={value ?? ""}
      onChange={onChange}
      type={type}
      inputClassName={type === "number" ? "hide-controls" : undefined}
      className="w-[240px]"
    />
  </div>
);

const SettingsClickableItem = ({
  title,
  value,
  buttonLink,
  buttonText,
}: {
  title: string;
  value: string | null;
  buttonLink: string;
  buttonText: string;
}) => (
  <div className="py-200 flex-row justify-between items-center">
    <div className="flex-col gap-100">
      <div className="body-md-semibold">{title}</div>
      <div className="text-secondary">{value}</div>
    </div>
    <Button
      variant="secondary"
      size="md"
      onClick={() => window.open(buttonLink, "_blank")}
    >
      {buttonText}
    </Button>
  </div>
);

const NavPrimaryItem = ({ title, icon }: { title: string; icon: IconName }) => (
  <div className="h-800 w-full px-150 flex-row-center gap-150">
    <Icon name={icon} size="md" className="stroke-secondary-rest" />
    <div className="flex-fill label-md-semibold text-disabled">{title}</div>
  </div>
);

const NavSecondaryItem = ({
  title,
  onClick,
  isSelected,
  isLoading,
  icon,
}: {
  title: string;
  onClick?: () => void;
  isSelected: boolean;
  isLoading?: boolean;
  icon?: IconName;
}) => (
  <BaseButton
    onClick={onClick}
    className={classNames(
      "w-full rounded-150",
      isSelected ? "bg-surface-primary-active" : "bg-surface-primary-rest",
    )}
  >
    <div className="h-800 pl-800 pr-200 flex-row-center gap-150">
      <div
        className={classNames(
          "flex-fill",
          isSelected
            ? "label-md-semibold text-primary"
            : "label-md-default text-secondary",
        )}
      >
        {title}
      </div>
      {icon &&
        (isLoading ? <Spinner size="xs" /> : <Icon name={icon} size="md" />)}
    </div>
  </BaseButton>
);

const UserItem = ({
  user,
  onRemoveClick,
}: {
  user: UserWorkspace;
  onRemoveClick: (userUuid: string) => void;
}) => {
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);
  return (
    <div key={user.uuid} className="w-full h-1200 flex-row">
      <div className="flex-row items-center">
        <div className="w-[368px] flex-row-center pr-200 gap-200">
          <Icon
            name="User"
            size="sm"
            className={classNames(
              user.is_owner ? "stroke-primary-disabled" : "",
            )}
          />
          <div className="flex-fill flex-col">
            <div className="label-md-semibold text-primary">
              {user.first_name} {user.last_name}
            </div>
            <div className="label-sm-semibold text-secondary">{user.email}</div>
          </div>
        </div>
        <div
          className={classNames(
            "w-[104px] flex-row-reverse px-200 label-sm-semibold",
            user.is_owner ? "text-disabled" : "text-primary",
          )}
        >
          {user.is_owner ? "Owner" : "Member"}
        </div>
      </div>
      <div className="flex-col-center w-800 h-full">
        <Popover
          isOpen={isPopoverOpen}
          align="start"
          side="bottom"
          className="!z-50 pointer-events-auto"
          content={
            <div className="flex-col p-200">
              <Button
                onClick={() => {
                  onRemoveClick(user.uuid);
                  setIsPopoverOpen(false);
                }}
                iconName="Trash"
                size="md"
                variant="tertiary"
                className="label-md-default text-primary"
              >
                Remove from workspace
              </Button>
            </div>
          }
        >
          <ClickableIcon
            name="Ellipsis"
            variant="tertiary"
            size="sm"
            disabled={user.is_owner}
            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
          />
        </Popover>
      </div>
    </div>
  );
};
