import { useQueryClient } from "@tanstack/react-query";
import { useWorkspace } from "../../../../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../../../../http/useAppMutation.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import { useSelectedStylesGenerationParams } from "../../../../../hooks/useSelectedStylesGenerationParams.ts";
import { useVariationSettings } from "./useVariationSettings.ts";

export const useVariationGeneration = () => {
  const { workspace } = useWorkspace();
  const { board } = useBoard();
  const { variationSettings } = useVariationSettings();

  const generateImageVariationMutation = useAppMutation({
    path: "boards/generate-image-variation",
    invalidate: [`workspaces/${workspace.uuid}/boards`, `boards/${board.uuid}`],
  }).mutation;

  const { selectedStylesGenerationParams } =
    useSelectedStylesGenerationParams();

  const queryClient = useQueryClient();
  return {
    mutation: ({
      prompt,
      assetUuid,
    }: {
      prompt: string;
      assetUuid: string;
    }) => {
      if (!selectedStylesGenerationParams.length) return;

      generateImageVariationMutation.mutate(
        {
          board_uuid: board.uuid,
          asset_uuid: assetUuid,
          prompt,
          style_uuid: selectedStylesGenerationParams[0].uuid,
          num_generations: variationSettings.num_generations,
          prompt_strength:
            variationSettings.creativity_strength_preset === "custom"
              ? variationSettings.prompt_strength
              : undefined,
          lora_scale: selectedStylesGenerationParams[0].scale,
          negative_prompt: variationSettings.negative_prompt,
          quality_preset: variationSettings.quality_preset,
          creativity_strength_preset:
            variationSettings.creativity_strength_preset,
          ml_model_architecture: variationSettings.ml_model_architecture,
          guidance_scale: variationSettings.guidance_scale,
          enable_flux_prompt_enhancement:
            variationSettings.enable_flux_prompt_enhancement,
        },
        {
          onSuccess: () => {
            [`assets/${assetUuid}`, `assets/${assetUuid}/history`].map(
              (queryKey) =>
                void queryClient.invalidateQueries({ queryKey: [queryKey] }),
            );
          },
        },
      );
    },
    isLoading: generateImageVariationMutation.isLoading,
  };
};
