import type { AxiosResponse } from "axios";
import { useAppMutation } from "../../../../../../../http/useAppMutation.ts";
import { useBoard } from "../../../../../hooks/useBoard.ts";
import { useSelectedStylesGenerationParams } from "../../../../../hooks/useSelectedStylesGenerationParams.ts";
import { useBriefSettings } from "./useBriefSettings.ts";

export const useImageFromBriefGeneration = ({
  onSuccess,
}: {
  onSuccess?: (
    response: AxiosResponse<{
      assets_uuids: string[];
    }>,
  ) => void;
}) => {
  const briefSettings = useBriefSettings();
  const { selectedStylesGenerationParams } =
    useSelectedStylesGenerationParams();
  const { board } = useBoard();
  const generateImagesFromBriefMutation = useAppMutation<{
    assets_uuids: string[];
  }>({
    path: "boards/generate-images-from-brief",
    invalidate: [`boards/${board.uuid}`, "boards", "users/me"],
    onSuccess,
  }).mutation;

  return {
    mutation: ({ prompt }: { prompt: string }) => {
      generateImagesFromBriefMutation.mutate({
        board_uuid: board.uuid,
        prompt,
        style_uuid: selectedStylesGenerationParams[0].uuid,
        lora_scale: selectedStylesGenerationParams[0].scale,
        quality_preset: briefSettings.quality_preset,
        ml_model_architecture: briefSettings.ml_model_architecture,
        enable_flux_prompt_enhancement:
          briefSettings.enable_flux_prompt_enhancement,
      });
    },
    isLoading: generateImagesFromBriefMutation.isLoading,
  };
};
