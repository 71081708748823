import { Button } from "../../components/Button/Button.tsx";
import { Icon } from "../../components/Icon/Icon.tsx";
import { useIsUserWorkspaceOwner } from "../../hooks/useIsUserWorkspaceOwner.ts";
import { useWorkspace } from "../../hooks/useWorkspace.ts";
import { useCreateCustomerPortalUrl } from "../../views/Home/HomeIndex/hooks/useCreateCustomerPortalUrl.ts";

export const UpgradeSubscriptionContent = ({
  onClickContinueWithCurrentVersion,
}: {
  onClickContinueWithCurrentVersion: () => void;
}) => {
  const isUserWorkspaceOwner = useIsUserWorkspaceOwner();
  const { workspace } = useWorkspace();
  const { createCustomerPortalUrl, isCreateCustomerPortalUrlLoading } =
    useCreateCustomerPortalUrl();

  return (
    <div className="flex-col w-[550px] gap-400 p-400 bg-surface-primary-rest">
      <div className="body-lg-semibold">
        You've reached your subscription's limit!
      </div>
      <div className="flex-col gap-400">
        <div className="text-base body-lg-default">
          {isUserWorkspaceOwner
            ? "Please upgrade your subscription to expand its capabilities."
            : "Please ask the workspace owner to upgrade the subscription to expand its capabilities."}
        </div>
        {isUserWorkspaceOwner && (
          <div className="flex-row justify-end gap-400">
            <Button
              variant="tertiary"
              onClick={onClickContinueWithCurrentVersion}
            >
              Continue with the current version
            </Button>
            <Button
              loading={isCreateCustomerPortalUrlLoading}
              onClick={() => {
                createCustomerPortalUrl({ workspace_uuid: workspace.uuid });
              }}
            >
              <Icon name="Lock" />
              Manage subscription
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
