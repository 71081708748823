import { Route } from "react-router";
import { Routes } from "react-router-dom";
import { FILL_TOOL } from "../../../../routes.ts";
import { GenerativeFillToolbar } from "./Tools/GenerativeFillView/GenerativeFillToolbar.tsx";

export const ToolBarContent = () => (
  <div className="w-full h-1200">
    <Routes>
      <Route
        path={FILL_TOOL}
        element={
          <div className="w-full h-full flex-row-center px-200 bg-surface-primary-rest">
            <GenerativeFillToolbar />
          </div>
        }
      />
    </Routes>
  </div>
);
