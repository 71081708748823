import classNames from "classnames";
import { Link } from "react-router-dom";
import { Icon } from "../../../components/Icon/Icon.tsx";
import { WORKSPACES } from "../../../routes.ts";

export const ModelCreationCloseButton = ({
  className,
}: {
  className: string;
}) => (
  <Link
    to={WORKSPACES}
    className={classNames(
      "rounded-100 border bg-surface-primary-rest hover:bg-surface-primary-hover",
      className,
    )}
  >
    <Icon name="X" size="sm" className="m-200" />
  </Link>
);
