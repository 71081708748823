import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppQuery } from "../../../../http/useAppQuery.ts";
import type { Style } from "../types.ts";

export const useStyles = () => {
  const { workspace } = useWorkspace();
  const stylesQuery = useAppQuery<Style[]>({
    queryKey: `workspaces/${workspace.uuid}/styles`,
  });

  const workspaceStyles = stylesQuery.data?.filter(
    (style) => !style.is_curated,
  );
  const libraryStyles = stylesQuery.data?.filter((style) => style.is_curated);

  return {
    workspaceStyles,
    libraryStyles,
  };
};
