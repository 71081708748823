import {
  BRIEF_TOOL,
  BRIEF_TOOL_PATH,
  FILL_TOOL,
  FILL_TOOL_PATH,
  GENERATE_TOOL,
  GENERATE_TOOL_PATH,
  TRANSFER_TOOL,
  TRANSFER_TOOL_PATH,
  UPSCALE_TOOL,
  UPSCALE_TOOL_PATH,
  VARIATION_TOOL,
  VARIATION_TOOL_PATH,
} from "../../../routes.ts";
import type { Tool } from "../types.ts";

// FIXME: it should be improved the tool's names can be in the uuids which can
//  create mismatch
export const getSelectedTool: () => Tool | undefined = () =>
  window.location.pathname.includes(GENERATE_TOOL_PATH)
    ? GENERATE_TOOL
    : window.location.pathname.includes(TRANSFER_TOOL_PATH)
    ? TRANSFER_TOOL
    : window.location.pathname.includes(VARIATION_TOOL_PATH)
    ? VARIATION_TOOL
    : window.location.pathname.includes(FILL_TOOL_PATH)
    ? FILL_TOOL
    : window.location.pathname.includes(UPSCALE_TOOL_PATH)
    ? UPSCALE_TOOL
    : window.location.pathname.includes(BRIEF_TOOL_PATH)
    ? BRIEF_TOOL
    : undefined;
