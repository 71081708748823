import { useAuth0 } from "@auth0/auth0-react";
import { useQueryClient } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useSyncRef } from "../hooks/useSyncRef.ts";

export const useAppSubscription = () => {
  const [isConnected, setIsConnected] = useState(false);
  const getAccessTokenSilently = useSyncRef(useAuth0().getAccessTokenSilently);
  const queryClient = useSyncRef(useQueryClient());

  useEffect(() => {
    let websocket: WebSocket;
    let cleanup = false;
    let attemptIndex = 0;

    const connect = () => {
      attemptIndex++;
      websocket = new WebSocket(
        `${window.location.origin.replace("http", "ws")}/api/ws`,
      );
      websocket.onopen = async () => {
        const accessToken = await getAccessTokenSilently.current();

        const payload = { token: accessToken };
        websocket.send(JSON.stringify(payload));
      };

      websocket.onmessage = (event) => {
        const data = JSON.parse(event.data);

        if (data.connected) {
          setIsConnected(true);
          attemptIndex = 0;
          // XXX: After websocket reconnection, we invalidate homepage and boards data to be sure to have the latest
          // data displayed. As all board query keys are composed with a single string starting with "boards", we can
          // use a predicate to invalidate all those queries.
          // FIXME: In order to avoid this predicate, we should use different keys and not a single string as query
          //  keys. Indeed, we don't have the possibility to invalidate a query with a prefix without using a predicate.
          //  For instance if the query board is defined with the queryKey ["boards", "boardUuid"], we could invalidate
          //  all boards queries by just specifying the queryKey ["boards"]. This would be more efficient and less
          //  hacky.
          void queryClient.current.invalidateQueries({
            predicate: (query) =>
              (query.queryKey[0] as string).startsWith("boards") ||
              ((query.queryKey[0] as string).startsWith("workspaces") &&
                (query.queryKey[0] as string).endsWith("boards")),
          });
        } else if (data.invalidate_queries) {
          void Promise.all(
            data.invalidate_queries.map((queryKey: string) =>
              queryClient.current.invalidateQueries({
                queryKey: [queryKey],
              }),
            ),
          );
        }
      };

      websocket.onclose = () => {
        if (cleanup) return;
        setIsConnected(false);
        setTimeout(
          connect,
          Math.min(attemptIndex === 1 ? 10 : 1000 * 2 ** attemptIndex, 30_000),
        );
      };
    };

    connect();

    return () => {
      cleanup = true;
      websocket.close();
      setIsConnected(false);
    };
  }, [queryClient, getAccessTokenSilently]);

  return { isConnected };
};
