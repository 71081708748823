import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppQuery } from "../../../../http/useAppQuery.ts";
import type { Board } from "../types.ts";

export const useBoards = () => {
  const { workspace } = useWorkspace();
  const boardsQuery = useAppQuery<Board[]>({
    queryKey: `workspaces/${workspace.uuid}/boards`,
  });

  return {
    userBoards: boardsQuery.data,
  };
};
