import React, { useLayoutEffect, useRef, useState } from "react";
import { BaseButton } from "../../components/Button/BaseButton.tsx";
import { Spinner } from "../../components/Spinner/Spinner.tsx";
import { useOnMount } from "../../hooks/useOnMount.ts";
import { useWorkspace } from "../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../http/useAppMutation.ts";
import { getConfig } from "../../utils/environment.ts";

declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace JSX {
    // eslint-disable-next-line @typescript-eslint/consistent-type-definitions
    interface IntrinsicElements {
      "stripe-pricing-table": React.DetailedHTMLProps<
        React.HTMLAttributes<HTMLElement> & {
          "pricing-table-id"?: string;
          "publishable-key"?: string;
          "customer-session-client-secret"?: string;
        },
        HTMLElement
      >;
    }
  }
}

export const PricingTable = ({
  onClickContinueWithCurrentVersion,
}: {
  onClickContinueWithCurrentVersion: () => void;
}) => {
  const { workspace } = useWorkspace();
  const [customerSessionSecret, setCustomerSessionSecret] = useState<string>();
  const { mutate: createCustomerSessionSecret } = useAppMutation({
    path: "payments/create-customer-session-secret",
    invalidate: [],
    onSuccess: (response: { data: { secret: string } }) => {
      setCustomerSessionSecret(response.data.secret);
    },
  }).mutation;

  useOnMount(() => {
    createCustomerSessionSecret({ workspace_uuid: workspace.uuid });
  });

  return customerSessionSecret ? (
    <div className="flex-col gap-100 bg-surface-primary-rest">
      <div className="w-[1000px] p-300">
        <StripePricingTable customerSessionSecret={customerSessionSecret} />
      </div>
      <BaseButton
        className="w-full p-200 flex-row-center bg-surface-primary-rest hover:bg-surface-primary-hover active:bg-surface-primary-active label-md-semibold"
        onClick={onClickContinueWithCurrentVersion}
      >
        Continue with the current version
      </BaseButton>
    </div>
  ) : (
    <div className="h-[600px] w-[1000px] flex-col-center">
      <Spinner />
    </div>
  );
};

const StripePricingTable = ({
  customerSessionSecret,
}: {
  customerSessionSecret: string;
}) => {
  const tableRef = useRef<HTMLElement>(null);
  useLayoutEffect(() => {
    // XXX: Small delay for Safari to properly handle custom element attributes.
    // It looks like a delay smaller than 500ms still doesn't work, and that we
    // need to wait "for a while" before even trying to set this property.
    const intervalId = setInterval(() => {
      tableRef.current?.setAttribute(
        "customer-session-client-secret",
        customerSessionSecret,
      );
    }, 50);
    return () => {
      clearInterval(intervalId);
    };
  }, [customerSessionSecret]);

  return (
    <stripe-pricing-table
      ref={tableRef}
      pricing-table-id={getConfig({
        dev: "prctbl_1QHq3CL31Z38889zDkrFi53Q",
        staging: "prctbl_1QHq3CL31Z38889zDkrFi53Q",
        prod: "prctbl_1QIsI0L31Z38889zIX7qs8z7",
      })}
      publishable-key={getConfig({
        dev: "pk_test_51Ng8VyL31Z38889z7oSOPWAtPQ8mnSK2f7rI4b08tF92tS8nBdATOzOwKSetgFNerkanKQ4mcjJyJLbIH86qAoYf00B3UOg0i5",
        staging:
          "pk_test_51Ng8VyL31Z38889z7oSOPWAtPQ8mnSK2f7rI4b08tF92tS8nBdATOzOwKSetgFNerkanKQ4mcjJyJLbIH86qAoYf00B3UOg0i5",
        prod: "pk_live_51Ng8VyL31Z38889zJkOcZ8ttUBZemqhHBUxT6ODrc4btjqhge2PcpIlRPKdSqDNSIXdxTssiTL6NIPbvdhsE6LQF00RRWnPOV1",
      })}
      customer-session-client-secret={customerSessionSecret}
    />
  );
};
