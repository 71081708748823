import { useState } from "react";
import { Button } from "../../../components/Button/Button.tsx";
import { Dialog } from "../../../components/Dialog/Dialog.tsx";
import { TagInput } from "../../../components/TagInput/TagInput.tsx";
import { useWorkspace } from "../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";

export const AddUsersDialog = ({
  isOpen,
  onOpenChange,
}: {
  isOpen: boolean;
  onOpenChange: (open: boolean) => void;
}) => (
  <Dialog
    title="Invite members"
    isOpen={isOpen}
    onOpenChange={onOpenChange}
    content={<UserEmailsTagInput onOpenChange={onOpenChange} />}
  />
);

const UserEmailsTagInput = ({
  onOpenChange,
}: {
  onOpenChange: (open: boolean) => void;
}) => {
  const [userEmails, setUserEmails] = useState<string[]>([]);
  const { workspace } = useWorkspace();

  const addUsers = useAppMutation({
    path: "workspaces/add-users",
    invalidate: ["users/me", `workspaces/${workspace.uuid}`],
  }).mutation;

  return (
    <div className="w-[432px] flex-col">
      <div className="py-100 px-400 flex-col gap-100">
        <div className="body-md-semibold text-primary">Email addresses</div>
        <TagInput
          tagValues={userEmails}
          onChange={(tags) => {
            setUserEmails(tags);
          }}
          placeholder="Ex: john@pimento.design" /* FIXME: Text truncated */
          size="md"
          isDisabled={false}
        />
        <div className="body-md-default text-secondary">
          Type or paste in emails above, separated by commas or press enter
        </div>
      </div>
      <div className="flex-row px-400 py-300 justify-end gap-200">
        <Button
          variant="secondary"
          onClick={() => onOpenChange(false)}
          size="md"
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          onClick={() => {
            addUsers.mutate({
              emails: userEmails,
              workspace_uuid: workspace.uuid,
            });
            onOpenChange(false);
          }}
          iconName="Send"
          size="md"
        >
          Add to workspace
        </Button>
      </div>
    </div>
  );
};
