import { useNavigate } from "react-router-dom";
import { Button } from "../../../../components/Button/Button.tsx";
import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { BOARDS, GENERATE_TOOL_PATH, WORKSPACES } from "../../../../routes.ts";
import { useCreateBoard } from "../hooks/useCreateBoard.ts";

export const NewBoardOrUpgrade = () => {
  const { workspace } = useWorkspace();
  const navigate = useNavigate();
  const { createBoard, isCreateBoardLoading } = useCreateBoard({
    onSuccess: (uuid) =>
      navigate(
        `${WORKSPACES}/${workspace.uuid}/${BOARDS}/${uuid}/${GENERATE_TOOL_PATH}`,
      ),
  });

  return (
    <Button
      className="group"
      size="md"
      onClick={() => createBoard({})}
      loading={isCreateBoardLoading}
    >
      <div className="flex-row items-center gap-200">New project</div>
    </Button>
  );
};
