import { Navigate, Route, useParams } from "react-router";
import { Routes } from "react-router-dom";
import { Query } from "../../components/Query/Query.tsx";
import { Spinner } from "../../components/Spinner/Spinner.tsx";
import { useUser } from "../../hooks/useUser.ts";
import { BOARDS, HOME, STYLES, WORKSPACES } from "../../routes.ts";
import { UpgradeDialog } from "../../singletons/UpgradeDialog/UpgradeDialog.tsx";
import { BoardView } from "../Board/Board.tsx";
import { HomeView } from "../Home/HomeView.tsx";
import { ModelCreationView } from "../StyleCreation/ModelCreationView.tsx";

export const WorkspaceRedirect = () => {
  const { user } = useUser();
  if (!user) return <Spinner />;

  return <Navigate replace to={`${WORKSPACES}/${user.workspaces[0].uuid}`} />;
};

export const WorkspaceView = () => {
  const workspaceUuid = useParams()["workspaceUuid"]!;
  return (
    <Query queryKey={`workspaces/${workspaceUuid}`}>
      {() => (
        <>
          <Routes>
            <Route path={`${HOME}/*`} element={<HomeView />} />
            <Route path={`${BOARDS}/:boardUuid/*`} element={<BoardView />} />
            <Route
              path={`${STYLES}/:styleUuid/*`}
              element={<ModelCreationView />}
            />
            <Route path="*" element={<Navigate replace to={HOME} />} />
          </Routes>
          <UpgradeDialog />
        </>
      )}
    </Query>
  );
};
