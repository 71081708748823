import classNames from "classnames";
import { useState } from "react";
import { BaseButton } from "../../../components/Button/BaseButton.tsx";
import { Button } from "../../../components/Button/Button.tsx";
import { Image } from "../../../components/Image/Image.tsx";
import { useWorkspace } from "../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../http/useAppMutation.ts";
import { STYLE_TYPES } from "../../constants.ts";
import type { Style, StyleType } from "../../types.ts";
import { ModelCreationCloseButton } from "../components/ModelCreationCloseButton.tsx";

export const StyleTypeForm = ({ style }: { style: Style }) => {
  const { workspace } = useWorkspace();
  const [styleType, setStyleType] = useState<StyleType>();

  const { mutate: updateStyle } = useAppMutation({
    path: "styles/update",
    invalidate: [`workspaces/${workspace.uuid}/styles`, `styles/${style.uuid}`],
  }).mutation;

  return (
    <div className="relative flex-col-center flex-shrink overflow-y-auto pt-2000 gap-[100px] text-primary">
      <ModelCreationCloseButton className="absolute top-400 right-400" />
      <span className="heading-2xl text-center">
        what do you want to focus on?
      </span>
      <div className="flex-row w-full justify-center flex-wrap gap-600">
        {STYLE_TYPES.map((it) => (
          <BaseButton
            key={it}
            onClick={() => setStyleType(it)}
            className={classNames(
              it === styleType
                ? "border-primary-active"
                : "hover:border-primary-hover",
              "p-400 rounded-100 border-025 flex-col gap-800",
            )}
          >
            <div className="flex-row-center gap-200">
              {STYLE_TYPE_CONTENTS[it].firstExampleUrls.map((url) => (
                <Image
                  key={url}
                  className="w-[60px] h-[60px] border rounded-050"
                  src={url}
                />
              ))}
            </div>
            <div className="flex-row items-center justify-between">
              <div className="body-lg-semibold">
                {STYLE_TYPE_CONTENTS[it].title}
              </div>
              {STYLE_TYPE_CONTENTS[it].hasBetaFlag && (
                <div className="py-050 px-200 bg-surface-primary-active rounded-050 flex-row-center label-md-default">
                  Beta
                </div>
              )}
            </div>
          </BaseButton>
        ))}
      </div>
      <Button
        style={{ width: "300px" }}
        size="md"
        disabled={styleType === undefined}
        onClick={() => {
          updateStyle({
            uuid: style.uuid,
            type: styleType,
          });
        }}
      >
        Next
      </Button>
    </div>
  );
};

const STYLE_TYPE_CONTENTS: Record<
  StyleType,
  { title: string; firstExampleUrls: string[]; hasBetaFlag: boolean }
> = {
  style: {
    title: "Aesthetic Style",
    firstExampleUrls: [
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/style_example2_1.png",
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/style_example2_2.png",
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/style_example2_3.png",
    ],
    hasBetaFlag: false,
  },
  object: {
    title: "Object",
    firstExampleUrls: [
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/object_example2_1.png",
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/object_example2_2.png",
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/object_example2_3.png",
    ],
    hasBetaFlag: true,
  },
  character: {
    title: "Character",
    firstExampleUrls: [
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/character_example1_1.png",
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/character_example1_2.png",
      "https://storage.googleapis.com/419c45cf-be8a-4cba-bbcd-74a221eb2587/app/assets/onboarding/style_type/character_example1_3.png",
    ],
    hasBetaFlag: true,
  },
};
