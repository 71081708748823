import { useLayoutEffect } from "react";
import { Navigate, Route } from "react-router";
import { Routes, useNavigate } from "react-router-dom";
import { useOnMount } from "../../../../hooks/useOnMount.ts";
import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import {
  BOARDS,
  FILL_TOOL,
  GENERATE_TOOL_PATH,
  UPSCALE_TOOL,
  VARIATION_TOOL,
  WORKSPACES,
} from "../../../../routes.ts";
import { useBoard } from "../../hooks/useBoard.ts";
import { useSelectedAssetUuid } from "../../hooks/useSelectedAssetUuid.ts";
import { editAssetMenuStore } from "../../stores/editAssetMenuStore.ts";
import { ImageContainer } from "./components/ImageContainer/ImageContainer.tsx";
import { ToolBarContent } from "./ToolBarContent.tsx";
import { GenerativeFillTool } from "./Tools/GenerativeFillView/GenerativeFillTool.tsx";

export const EditWorkspace = () => {
  const { workspace } = useWorkspace();
  const { board } = useBoard();
  const { selectedAssetUuid, setSelectedAssetUuid, clearSelectedAssetUuid } =
    useSelectedAssetUuid();

  const navigate = useNavigate();

  useOnMount(() => editAssetMenuStore.setCurrentAssetMenuType("history"));

  useLayoutEffect(() => {
    if (!board.assets.some((asset) => asset.uuid === selectedAssetUuid)) {
      board.assets
        .filter(
          (asset) =>
            asset.content.status === "succeeded" ||
            asset.content.status === "processing",
        )
        .let((assets) => {
          if (assets.length > 0) {
            setSelectedAssetUuid(assets[0].uuid);
          } else {
            clearSelectedAssetUuid();
            // If no assets selected, we return to create workspace
            navigate(
              `${WORKSPACES}/${workspace.uuid}/${BOARDS}/${board.uuid}/${GENERATE_TOOL_PATH}`,
            );
          }
        });
    }
  }, [
    clearSelectedAssetUuid,
    selectedAssetUuid,
    workspace.uuid,
    board.uuid,
    board.assets,
    setSelectedAssetUuid,
    navigate,
  ]);

  return (
    <div className="flex-fill flex-col justify-end">
      <div className="flex-fill flex-col gap-800">
        <ToolBarContent />
        <div className="px-600 flex-fill flex-row gap-600">
          {/* FIXME: Instead of defining a content view per tool, we should use a single component `ImageContainer` for all
           edit tools instead of redefining different ones per tools. To do so:
            - Unify the use of canvas for all edit tools
            This will remove all flash when switching between tools */}
          <div className="flex-fill flex-col-center">
            <Routes>
              <Route index element={<ImageContainer />} />
              <Route path={VARIATION_TOOL} element={<ImageContainer />} />
              <Route path={FILL_TOOL} element={<GenerativeFillTool />} />
              <Route path={UPSCALE_TOOL} element={<ImageContainer />} />
              <Route path="*" element={<Navigate replace to="" />} />
            </Routes>
          </div>
        </div>
        <div className="w-full h-[48px]" />
      </div>
    </div>
  );
};
