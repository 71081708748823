import { BaseButton } from "../../../../components/Button/BaseButton.tsx";
import { EditableText } from "../../../../components/EditableText/EditableText.tsx";
import { PimentoStar } from "../../../../components/Icon/assets/PimentoStar.tsx";
import { CDNImage } from "../../../../components/Image/CDNImage.tsx";
import { useUser } from "../../../../hooks/useUser.ts";
import { useWorkspace } from "../../../../hooks/useWorkspace.ts";
import { useAppMutation } from "../../../../http/useAppMutation.ts";
import { isUserAllowedTo } from "../../../../types.ts";
import { GENERIC_STYLE_UUID } from "../../../constants.ts";
import { useUserCanOpenStyleTagMenu } from "../hooks/useUserCanOpenStyleTagMenu.ts";
import type { Style } from "../types.ts";
import { StyleExtraMenu } from "./StyleExtraMenu.tsx";

export const StyleTile = ({
  style,
  onClick,
  isLoading,
  disabled,
  isEditable = true,
}: {
  style: Style;
  onClick?: () => void;
  isLoading?: boolean;
  disabled?: boolean;
  isEditable?: boolean;
}) => {
  const { user } = useUser();
  const { workspace } = useWorkspace();
  const userCanOpenStyleTagMenu = useUserCanOpenStyleTagMenu();

  const { mutate: nameMutate, isLoading: nameIsloading } = useAppMutation({
    path: "styles/update",
    invalidate: [`workspaces/${workspace.uuid}/styles`, `styles/${style.uuid}`],
  }).mutation;

  return (
    <div className="group relative flex-col-center w-[180px]">
      <BaseButton
        className="w-full"
        style={{ height: 220 }}
        onClick={onClick}
        loading={isLoading}
        disabled={disabled}
      >
        <div className="relative group w-full h-full">
          {style.thumbnail_url ? (
            /* FIXME: in order to have good quality images without watermark we rely here on the fact that
            we fetch from the CDN without watermark, even if the size is above the limit in CF */
            <CDNImage
              src={style.thumbnail_url}
              className="h-full w-full rounded-100"
              imageClassName="h-full w-full object-cover object-center group-hover:scale-110"
              loading="lazy"
              srcDimension="large512"
            />
          ) : (
            <div className="h-full w-full rounded-050 overflow-clip">
              {style.uuid === GENERIC_STYLE_UUID ? (
                <div className="flex-col-center h-full w-full bg-surface-emphasis-primary-rest group-hover:scale-110">
                  <PimentoStar size={70} className="fill-button-primary-rest" />
                </div>
              ) : (
                <div className="flex-col-center h-full w-full bg-input-surface-rest" />
              )}
            </div>
          )}
          {["created", "training"].includes(style.status) ? (
            <div className="absolute left-0 top-0 w-full h-full bg-[rgb(200,200,200,0.85)] text-white flex-row-center label-lg-semibold">
              {style.status === "created" ? "Draft" : "Training"}
            </div>
          ) : null}
          <div className="absolute left-0 bottom-0 rounded-b-100 w-full h-full bg-gradient-to-t from-[#494949]/80 to-80%" />
        </div>
      </BaseButton>
      <div className="absolute bottom-0 w-[90%] h-1000 flex-row items-center">
        {isEditable ? (
          <EditableText
            text={style.name ?? "Untitled"}
            onTextChange={(name) => nameMutate({ uuid: style.uuid, name })}
            className="h-full flex-row items-center"
            textClassName="text-white body-lg-semibold"
            inputClassName="bg-surface-transparent-primary-rest rounded-100 body-lg-semibold text-white"
            isLoading={nameIsloading}
          />
        ) : (
          <span className="w-full h-full flex-row items-center truncate body-lg-semibold text-white">
            {style.name ?? "Untitled"}
          </span>
        )}
      </div>
      {user &&
        ((!style.is_curated && isUserAllowedTo(user, "styles:delete")) ||
          isUserAllowedTo(user, "styles:curate") ||
          userCanOpenStyleTagMenu) && (
          <div className="absolute right-100 top-100">
            <StyleExtraMenu
              style={style}
              className="invisible group-hover:visible"
            />
          </div>
        )}
    </div>
  );
};
